const initState = { active_study_id: null };

export default function studyReducer(state = initState, action) {
  switch (action.type) {
    case "set_active_study_id":
      return { ...state, active_study_id: action.payload };
    default:
      return state;
  }
}
