import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./sites.module.css";
import { get } from "util/requests";
import router from "routes";
import LoadingButton from "@mui/lab/LoadingButton";

const columns = [
  { field: "code", headerName: "Site ID", width: 160 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "location", headerName: "Location", width: 150 },
  {
    field: "time_zone",
    headerName: "Time Zone",
    width: 150,
  },
  {
    field: "is_enabled",
    headerName: "Enabled",
    width: 180,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (row.is_randomization_enabled ? "YES" : "NO"),
  },
  {
    field: "is_econsent_enabled",
    headerName: "eConsent Enabled",
    width: 160,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (row.is_econsent_enabled ? "YES" : "NO"),
  },
  {
    field: "is_able_to_enroll",
    headerName: "Randomization Enabled",
    width: 200,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (row.is_able_to_enroll ? "YES" : "NO"),
  },
];

const rows = [
  { id: "001", lastName: "Snow", firstName: "Jon", age: 35 },
  { id: "002", lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: "003", lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: "004", lastName: "Stark", firstName: "Arya", age: 16 },
  { id: "005", lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: "006", lastName: "Melisandre", firstName: null, age: 150 },
  { id: "007", lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: "008", lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: "009", lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export default function SiteTable() {
  const study_id = useSelector((state) => state.study.active_study_id);
  const [siteData, setSiteData] = useState([]);
  useEffect(() => {
    get(`/sites/${study_id}`).then((ret) => setSiteData(ret.data));
    // document.addEventListener("mousedown", handleDocumentClick);
  }, []);

  return (
    <div>
      <LoadingButton
        variant="contained"
        loading={false}
        style={{ marginBottom: 6 }}
        onClick={() => router.navigate("/admin/site/add")}
      >
        ADD SITE
      </LoadingButton>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={siteData}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          disableColumnMenu
          pageSize={5}
          rowsPerPageOptions={[5]}
          hideFooterSelectedRowCount
          pageSizeOptions={[]}
          columnHeaderHeight={34}
          rowHeight={34}
          sx={{
            boxShadow: 0,
            border: 1,
            borderColor: "#f0f0f0",
            "& .MuiDataGrid-cell:hover": {
              color: "green",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9f1fd",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
              color: "#333",
            },
          }}
          onRowClick={(row) => {
            router.navigate(`/admin/sites/${row.id}`);
          }}
        />
      </div>
    </div>
  );
}
