import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import styles from "../subjects.module.css";

export default function AllDomainsTable(props) {
  const { columns_domain, selectedDomain } = props;
  const [patientData, setPatientData] = useState([]);

  useEffect(() => {
    get("/subjects", { params: { selectedDomain } }).then((ret) =>
      setPatientData(ret.data),
    );
  }, [selectedDomain]);

  return (
    <DataGrid
      rows={patientData}
      columns={columns_domain}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={(row) => {
        router.navigate(`/study/1/site/1/subjects/${row.id}/view`);
      }}
      components={{
        NoRowsOverlay: () => (
          <div className={styles.no_subjects_found}>No Subjects Available</div>
        ),
      }}
    />
  );
}
