import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import moment from "moment";
import styles from "./crf.module.css";
import LoadingButton from "@mui/lab/LoadingButton";

const default_columns = [
  {
    field: "created_on",
    headerName: "Created On",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.created_on &&
        moment(row.created_on).format("yyyy-MMM-DD HH:mm:ss")) ||
      "",
  },
  {
    field: "modified_on",
    headerName: "Modified On",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.modified_on &&
        moment(row.modified_on).format("yyyy-MMM-DD HH:mm:ss")) ||
      "",
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "open_queries",
    headerName: "Open Queries",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
];

const ae_columns = [
  {
    field: "created_on",
    headerName: "Created On",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.created_on &&
        moment(row.created_on).format("yyyy-MMM-DD HH:mm:ss")) ||
      "",
  },
  {
    field: "modified_on",
    headerName: "Modified On",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.modified_on &&
        moment(row.modified_on).format("yyyy-MMM-DD HH:mm:ss")) ||
      "",
  },
  {
    field: "start_date",
    headerName: "Start Date",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "term",
    headerName: "AE Term",
    width: 300,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      row.term && row.term.length > 30
        ? row.term.slice(0, 40) + "..."
        : row.term || "",
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "open_queries",
    headerName: "Open Queries",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
];

export function LogFormTable(props) {
  const {
    crf_id,
    subject_id,
    domain_id,
    updateCrfStatus,
    form_type,
    multi_entry_crf_id,
  } = props;
  const [queries, setQueries] = useState([]);

  useEffect(() => {
    get(`/multi_entry_crfs/${subject_id}/${crf_id}`).then((ret) =>
      setQueries(ret.data),
    );
  }, [crf_id, subject_id]);

  function getColumns() {
    if (crf_id === "adverse_events") {
      return ae_columns;
    } else {
      return default_columns;
    }
  }
  return (
    <DataGrid
      rows={queries}
      columns={getColumns()}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={(row) => {
        router.navigate(
          `/study/1/site/1/subjects/${subject_id}/edc/visit/1/multi_entry/${crf_id}/${row.id}`,
        );
      }}
      components={{
        NoRowsOverlay: () => (
          <div className={styles.no_rows_overlay}>No CRF Data</div>
        ),
      }}
    />
  );
}

export default function LogFormTableWrapper(props) {
  const { crf_id, subject_id } = props;
  const [queries, setQueries] = useState([]);
  const [crfName, setCrfName] = useState("");

  useEffect(() => {
    get(`/crf_name_from_id/${crf_id}`).then((ret) => setCrfName(ret.data.name));
  }, [crf_id]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.subject_title}>
        <strong> Subject: {props.subject_id}</strong>
      </div>
      <div>
        <div className={styles.stretch_header}>
          <div>
            <strong>{crfName && crfName.toUpperCase()}</strong>
          </div>
          <div>
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() =>
                router.navigate(
                  `/study/1/site/1/subjects/${subject_id}/edc/visit/1/multi_entry/${crf_id}/${crypto.randomUUID()}`,
                )
              }
            >
              <strong>ADD NEW</strong>
            </LoadingButton>
          </div>
        </div>
      </div>

      <LogFormTable {...props} />
    </div>
  );
}
