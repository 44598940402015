import React, { forwardRef } from "react";
import styles from "../subjects.module.css";
import { Checkbox } from "@mui/material";

const menu_style = [styles.hover_menu, styles.column_menu].join(" ");

const ModifySubjectColumnsMenu = (props, ref) => {
  const { availableColumns, selectedColumns, setColumnShowingState } = props;
  return (
    <div className={menu_style} ref={ref}>
      {availableColumns.map((row) => (
        <div key={row.field} className={styles.column_menu_button}>
          <Checkbox
            key={row.field}
            className={styles.checkbox}
            sx={{ "&  .MuiCheckbox-root": { fontSize: 10, padding: 0 } }}
            checked={selectedColumns.includes(row.field)}
            onChange={(e) => setColumnShowingState(row.field, e.target.checked)}
          />
          {row.headerName}
        </div>
      ))}
    </div>
  );
};

export default forwardRef(ModifySubjectColumnsMenu);
