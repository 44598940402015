import React, { useState, useRef, useEffect } from "react";
import styles from "./time.module.css";
import * as requests from "util/requests";
import { TextField, Checkbox } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import { pad_time_value, parseTime } from "util/ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";

let tempStorage = null;
let newValueObj = null;

export default function Time(props) {
  const {
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    time_position = "value",
    crfStatus,
    read_only,
  } = props;
  const [oldValue, setOldValue] = useState(uval);
  const [hours, setHours] = useState("");
  const [hoursHasChanged, setHoursHasChanged] = useState(null);
  const [minutes, setMinutes] = useState("");
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const [valueObj, setValueObj] = useState((field && field.value_obj) || {});
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  const setTimeFromObj = () => {
    let time = field.value_obj[time_position];
    time = parseTime(time);
    setHours(time.hours);
    setMinutes(time.minutes);
  };

  useEffect(() => {
    setTimeFromObj();
  }, [field.value_obj]);

  const commonUpdateValueActions = (key, value) => {
    let obj = { ...valueObj };
    obj.value_name = field.value_name;
    obj.value2_name = field.value2_name;

    if (key === "hours" && minutes) {
      obj[time_position] = `${value}:${minutes}`;
    }
    if (key === "minutes" && hours) {
      obj[time_position] = `${hours}:${value}`;
    }
    setValueObj(obj);

    if (RegExp(/[0-9]{2}:[0-9]{2}/).test(obj[time_position])) {
      //don't update upstream data, including tracking if the data entry is incomplete
      onUpdate(obj);
    }
  };
  const updateValue = (key, value) => {
    if (crfStatus === "data_complete") {
      tempStorage = valueObj;
      newValueObj = { key, value };
      setShowingCompleteChangeModal(true);
      return;
    }
    commonUpdateValueActions(key, value);
  };

  const onKeyDownHours = (key) => {
    if (key.keyCode === 13) {
      hoursRef.current.blur();
    }
  };
  const onKeyDownMinutes = (key) => {
    if (key.keyCode === 13) {
      minutesRef.current.blur();
    }
  };

  const onFocusHours = (event) => {};

  useEffect(() => {
    if (
      hours &&
      hours.length === 2 &&
      hours < 24 &&
      crfStatus !== "data_complete"
    )
      minutesRef.current && minutesRef.current.focus();
  }, [hoursHasChanged]);

  const closeModal = () => {
    setTimeFromObj();
    setShowingCompleteChangeModal(false);
  };
  const acceptTempStorage = () => {
    commonUpdateValueActions(newValueObj.key, newValueObj[time_position]);
    newValueObj = null;
    setShowingCompleteChangeModal(false);
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          <NotificationWidget
            title={title}
            query
            {...props}
            currentValueDisplay={`${hours}:${minutes}`}
          />
        </div>
        {read_only ? (
          <div>{valueObj[time_position]}</div>
        ) : (
          <div className={[styles["right-split"], styles.time_entry].join(" ")}>
            <div>
              <TextField
                type="text"
                inputRef={hoursRef}
                placeholder="HH"
                value={hours}
                onChange={(e) => {
                  if (!/^([0-9]+){0,2}$/.test(e.target.value)) {
                    return;
                  }
                  if (hours <= 24) {
                    setHoursHasChanged(Math.random());
                  }
                  setHours(e.target.value);
                }}
                onBlur={(e) => {
                  if (hours === "") {
                    setHours(parseTime(valueObj[time_position]).hours);
                  } else if (hours < 24) {
                    let display = pad_time_value(hours);
                    setHours(display);
                    updateValue("hours", display);
                  }
                }}
                onKeyDown={onKeyDownHours}
                inputProps={{ maxLength: 2 }}
                autoComplete="off"
                onFocus={onFocusHours}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "30px",
                  },
                }}
                error={hours >= 24}
              />
            </div>
            <div className={styles.colon}>:</div>
            <div>
              <TextField
                type="text"
                inputRef={minutesRef}
                placeholder="MM"
                value={minutes}
                onChange={(e) => {
                  if (!/^([0-9]+){0,2}$/.test(e.target.value)) {
                    return;
                  }
                  setMinutes(e.target.value);
                }}
                onBlur={(e) => {
                  if (minutes === "") {
                    setMinutes(parseTime(valueObj[time_position]).minutes);
                  } else if (minutes < 60) {
                    let display = pad_time_value(minutes);
                    setMinutes(display);
                    updateValue("minutes", display);
                  }
                }}
                onKeyDown={onKeyDownMinutes}
                inputProps={{ maxLength: 2 }}
                autoComplete="off"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "30px",
                  },
                }}
                error={minutes >= 60}
              />
            </div>
            <div className={styles.input_description}>(24h clock, HH:MM)</div>
          </div>
        )}
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
