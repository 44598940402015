import React, { forwardRef } from "react";
import styles from "../subjects.module.css";
import { get } from "util/requests";

const DownloadSubjectsMenu = (props, ref) => {
  const {
    selectedDomain,
    selectedColumns,
    all_column_set_all,
    all_columns_domain,
  } = props;
  const downloadSubjects = () => {
    get("/download_subjects", {
      params: {
        columns: selectedColumns.join(","),
        domain_code: selectedDomain,
      },
    }).then((ret) => {
      const anchor = document.createElement("a");
      const type = "data:application/octet-stream";
      const url = "test.csv";
      const filename = "test.csv";
      const newWindow = true;

      anchor.setAttribute("href", `${type},${encodeURIComponent(ret.data)}`);
      anchor.setAttribute("download", filename);
      anchor.style.display = "none";
      anchor.textContent = url;

      if (newWindow) {
        anchor.setAttribute("target", "_blank");
      }

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  };
  return (
    <div className={styles.hover_menu} ref={ref}>
      <div className={styles.menu_button} onClick={downloadSubjects}>
        CSV
      </div>
    </div>
  );
};

export default forwardRef(DownloadSubjectsMenu);
