import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styles from "./date.module.css";
import moment from "moment";
export default function Date(props) {
  const { value, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [localVal, setLocalVal] = useState(null);
  const handleOnChange = (value) => {
    if (value && value.isValid()) onChange(value.format("yyyy-MM-DD"));
  };

  const openDateUI = () => {
    setIsOpen(true);
  };
  const closeDateUI = () => {
    setIsOpen(false);
  };
  const onBlur = () => {};
  const onKeyDown = () => {};

  return (
    <div className={styles.date_entry}>
      <div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            open={isOpen}
            format="YYYY / MM / DD"
            value={(value && moment(value, "yyyy-MM-DD")) || null}
            sx={{
              "& .MuiInputBase-input": {
                padding: "5px 10px",
                width: "120px",
              },
              "& .MuiInputAdornment-root": { marginLeft: 0 },
              "& .MuiButtonBase-root": { paddingLeft: 0 },
            }}
            onChange={handleOnChange}
            onOpen={openDateUI}
            onClose={closeDateUI}
            slotProps={{ textField: { onBlur, onKeyDown } }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
