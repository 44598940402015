import React, { useState, useRef, useEffect } from "react";
import styles from "../blood_pressure.module.css";
import { TextField } from "@mui/material";

export default function BloodPressureUI(props) {
  const {
    key_id,
    field,
    onUpdate,
    systolic_position = "value",
    diastolic_position = "value2",
    setParentFieldError,
    read_only,
  } = props;

  const [systolic, setSystolic] = useState(
    (field.value_obj && field.value_obj.value) || "",
  );
  const [diastolic, setDiastolic] = useState(
    (field.value_obj && field.value_obj.value2) || "",
  );

  useEffect(() => {
    setSystolic((field.value_obj && field.value_obj[systolic_position]) || "");
    setDiastolic(
      (field.value_obj && field.value_obj[diastolic_position]) || "",
    );
  }, [field.value_obj]);

  const field1Ref = useRef(null);
  const field2Ref = useRef(null);

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      field1Ref.current.blur();
      field2Ref.current.blur();
    }
  };

  const updateValue = (key, value) => {
    let obj = {
      ...field.value_obj,
    };
    if (key === "systolic") {
      obj[systolic_position] = value;
    }
    if (key === "diastolic") {
      obj[diastolic_position] = value;
    }
    onUpdate(obj);
  };

  const is_error = (values, context) => {
    if (
      context === "systolic" &&
      (!values.systolic || values.systolic.length === 0)
    ) {
      return false;
    }
    if (
      context === "diastolic" &&
      (!values.diastolic || values.diastolic.length === 0)
    ) {
      return false;
    }

    let systolic = (values.systolic && parseInt(values.systolic)) || "";
    let diastolic = (values.diastolic && parseInt(values.diastolic)) || "";
    let systolic_error = systolic < 30 || systolic > 300;
    let diastolic_error = diastolic < 30 || diastolic > 300;
    if (context === "both") {
      return systolic_error || diastolic_error;
    } else if (context === "systolic") {
      return systolic_error;
    } else if (context === "diastolic") {
      return diastolic_error;
    }
    return false;
  };

  return (
    <div className={styles.input_flex}>
      <div className={styles.systolic_wrapper}>
        <div className={styles.input_title}>Systolic BP (mmHg)</div>
        {read_only ? (
          <div>{systolic || "---"}</div>
        ) : (
          <TextField
            type="text"
            inputRef={field1Ref}
            placeholder=""
            value={systolic}
            onChange={(e) => {
              let value = e.target.value;
              if (/^([0-9]+){0,3}$/.test(value)) {
                setSystolic(value);
              }
              if (is_error({ systolic: value, diastolic }, "both")) {
                setParentFieldError && setParentFieldError(key_id, true);
              } else {
                setParentFieldError && setParentFieldError(key_id, false);
              }
            }}
            onKeyDown={onKeyDown}
            onBlur={() => {
              updateValue("systolic", systolic);
            }}
            inputProps={{ maxLength: 3 }}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "5px",
                textAlign: "center",
                width: "50px",
                fontSize: 14,
              },
            }}
            error={is_error({ systolic, diastolic }, "systolic")}
          />
        )}
      </div>

      <div>
        <div className={styles.input_title}>Diastolic BP (mmHg)</div>
        {read_only ? (
          <div>{diastolic || "---"}</div>
        ) : (
          <TextField
            type="text"
            inputRef={field2Ref}
            placeholder=""
            value={diastolic}
            onChange={(e) => {
              let value = e.target.value;
              if (/^([0-9]+){0,3}$/.test(value)) {
                setDiastolic(value);
              }
              if (is_error({ systolic, diastolic: value }, "both")) {
                setParentFieldError && setParentFieldError(key_id, true);
              } else {
                setParentFieldError && setParentFieldError(key_id, false);
              }
            }}
            onKeyDown={onKeyDown}
            onBlur={() => {
              updateValue("diastolic", diastolic);
            }}
            inputProps={{ maxLength: 3 }}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "5px",
                textAlign: "center",
                width: "50px",
                fontSize: 14,
              },
            }}
            error={is_error({ systolic, diastolic }, "diastolic")}
          />
        )}
      </div>
    </div>
  );
}
