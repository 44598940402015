import React, { useEffect, useState } from "react";
import { get, post } from "util/requests";
import styles from "./notification_window.module.css";
import moment from "moment";
import { TextField, Checkbox } from "@mui/material";
import { Dialog } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NotificationWindow(props) {
  const {
    crf_name,
    showing,
    closeNotificationWindow,
    query,
    data_name,
    subject_id,
    crf_id,
    field_id,
    domain_id,
    visit_index,
    setQuery,
    calcUpdateToCrfStatus,
    currentValueDisplay = "",
    form_type,
    multi_entry_crf_id,
    crf_slug,
  } = props;
  const permissions = useSelector((state) => state.user.userData.permissions);

  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [forceFormClear, setForceFormClear] = useState(false);

  const IS_ADD_QUERY = !query.id;
  const IS_RESOLVED_QUERY = query.id && query.status === "resolved";
  const IS_ACTIVE_QUERY =
    query.id && ["open", "responded", "action", "okay"].includes(query.status);
  const title = IS_ADD_QUERY ? "ADD QUERY" : "QUERIES";
  useEffect(() => {
    if (query.id) {
      get(`/query_messages/${query.id}`).then((ret) => setMessages(ret.data));
    }
  }, []);

  const getQueryDirection = () => {
    if (permissions.includes("start_query")) {
      return "start_query";
    } else if (permissions.includes("respond_query")) {
      return "respond_query";
    }
  };

  const addQuery = (
    domain_id,
    subject_id,
    crf_id,
    field_id,
    message,
    pathname,
    visit,
  ) => {
    post("/query", {
      domain_id,
      subject_id,
      crf_id,
      field_id,
      message,
      pathname,
      visit,
      form_type,
      multi_entry_crf_id,
      direction: getQueryDirection(),
      action: "open",
      crf_slug,
    }).then(({ data }) => {
      let new_messages = [...messages];
      new_messages.push(data.message);
      setMessages(new_messages);
      setQuery(
        form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
        field_id,
        data.query,
      );
      closeNotificationWindow();
      setNewMessage("");
      calcUpdateToCrfStatus(
        form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
        data.crf_query_status,
      );
    });
  };

  const resolveQuery = (
    query_id,
    domain_id,
    subject_id,
    crf_id,
    field_id,
    newMessage,
    pathname,
    visit,
  ) => {
    //leave message here so that we can locale it as needed later on
    let message = "(resolved) " + newMessage;
    setNewMessage(message);
    post("/query", {
      query_id,
      domain_id,
      subject_id,
      crf_id,
      field_id,
      message,
      pathname,
      visit,
      form_type,
      multi_entry_crf_id,
      direction: getQueryDirection(),
      action: "resolved",
      crf_slug,
    }).then(({ data }) => {
      if (data.crf_query_status) {
        calcUpdateToCrfStatus(
          form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
          data.crf_query_status,
        );
      }
      setQuery(
        form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
        field_id,
        data.query,
      );
      closeNotificationWindow();
    });
  };

  const reOpenQuery = (
    query_id,
    domain_id,
    subject_id,
    crf_id,
    field_id,
    newMessage,
    pathname,
    visit,
  ) => {
    //leave message here so that we can locale it as needed later on
    let message = "(re-opened)";
    post("/query", {
      query_id,
      domain_id,
      subject_id,
      crf_id,
      field_id,
      message,
      pathname,
      visit,
      form_type,
      multi_entry_crf_id,
      action: "open",
      direction: getQueryDirection(),
      crf_slug,
    }).then(({ data }) => {
      let new_messages = [...messages];
      new_messages.push(data.message);
      setMessages(new_messages);
      setQuery(
        form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
        field_id,
        data.query,
      );
      if (data.crf_query_status) {
        calcUpdateToCrfStatus(
          form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
          data.crf_query_status,
        );
      }
      closeNotificationWindow();
      setNewMessage("");
    });
  };

  const addQueryMessage = (
    query_id,
    domain_id,
    subject_id,
    crf_id,
    field_id,
    message,
    pathname,
    visit,
  ) => {
    post("/query", {
      query_id,
      domain_id,
      subject_id,
      crf_id,
      field_id,
      message,
      pathname,
      visit,
      form_type,
      multi_entry_crf_id,
      action: "responded",
      direction: getQueryDirection(),
      crf_slug,
    }).then(({ data }) => {
      let new_messages = [...messages];
      new_messages.push(data.message);
      setMessages(new_messages);
      setQuery(
        form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
        field_id,
        data.query,
      );
      if (data.crf_query_status) {
        calcUpdateToCrfStatus(
          form_type === "multi_entry" ? multi_entry_crf_id : crf_id,
          data.crf_query_status,
        );
      }
      closeNotificationWindow();
      setNewMessage("");
    });
  };

  const setFormOpenForNewQuery = () => {
    //this is going to reset the form
    setMessages([]);
    setNewMessage("");
    setForceFormClear(true);
  };

  return (
    <Dialog open={showing} onClose={closeNotificationWindow}>
      <div className={styles.hover_menu}>
        <div>
          <div className={styles.title_flex}>
            <div className={styles.message_box_title}>{title}</div>
          </div>
          <div>
            <div>
              <span className={styles.label}>CRF:</span>
              {crf_name}
            </div>
          </div>
          <div>
            <div>
              <span className={styles.label}>Field:</span>
              {data_name}
            </div>
          </div>
          <div>
            <div>
              <span className={styles.label}>Value:</span>
              {currentValueDisplay}
            </div>
          </div>
          <hr />
          <div>
            {messages.map((query_mess) => (
              <div
                className={
                  query_mess && query_mess.is_response
                    ? styles.right_message
                    : styles.left_message
                }
              >
                <div className={styles.date}>
                  {query_mess.user_name}{" "}
                  {moment(query_mess.created_on).format("YYYY-MM-DD HH:mm:ss")}
                </div>
                <div>{query_mess.message}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          {(permissions.includes("start_query") ||
            permissions.includes("respond_query")) &&
          (!IS_RESOLVED_QUERY || forceFormClear) ? (
            <div>
              <TextField
                multiline
                minRows={2}
                maxRows={2}
                autoComplete="off"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { padding: "10px" },
                  "& .MuiInputBase-input": {
                    padding: "0px",
                    textAlign: "left",
                    fontSize: 14,
                  },
                }}
                fullWidth
              />
            </div>
          ) : null}

          <React.Fragment>
            {IS_ADD_QUERY || forceFormClear ? (
              <div className={styles.button_wrapper}>
                <LoadingButton
                  variant="contained"
                  loading={false}
                  onClick={() => {
                    addQuery(
                      domain_id,
                      subject_id,
                      crf_id,
                      field_id,
                      newMessage,
                      location.pathname,
                      visit_index,
                    );
                  }}
                  disabled={!newMessage.length}
                >
                  Add
                </LoadingButton>
                <LoadingButton
                  variant="standard"
                  loading={false}
                  onClick={closeNotificationWindow}
                >
                  Close
                </LoadingButton>
              </div>
            ) : null}
            {IS_ACTIVE_QUERY ? (
              <div className={styles.button_wrapper}>
                {permissions.includes("respond_query") ? (
                  <LoadingButton
                    variant="contained"
                    loading={false}
                    onClick={() =>
                      addQueryMessage(
                        query.id,
                        domain_id,
                        subject_id,
                        crf_id,
                        field_id,
                        newMessage,
                        location.pathname,
                        visit_index,
                      )
                    }
                    disabled={!newMessage.length}
                  >
                    Respond
                  </LoadingButton>
                ) : null}
                {permissions.includes("resolve_query") ? (
                  <LoadingButton
                    variant="contained"
                    loading={false}
                    onClick={() =>
                      resolveQuery(
                        query.id,
                        domain_id,
                        subject_id,
                        crf_id,
                        field_id,
                        newMessage,
                        location.pathname,
                        visit_index,
                      )
                    }
                    sx={{ marginLeft: "10px" }}
                  >
                    Resolve
                  </LoadingButton>
                ) : null}

                <LoadingButton
                  variant="standard"
                  loading={false}
                  onClick={closeNotificationWindow}
                  sx={{ marginLeft: "10px" }}
                >
                  Close
                </LoadingButton>
              </div>
            ) : null}
            {IS_RESOLVED_QUERY && !forceFormClear ? (
              <div className={styles.button_wrapper}>
                {permissions.includes("start_query") ? (
                  <LoadingButton
                    variant="contained"
                    loading={false}
                    onClick={() => {
                      setFormOpenForNewQuery(
                        domain_id,
                        subject_id,
                        crf_id,
                        field_id,
                        newMessage,
                      );
                    }}
                  >
                    Add New
                  </LoadingButton>
                ) : null}
                {permissions.includes("start_query") ? (
                  <LoadingButton
                    variant="contained"
                    loading={false}
                    onClick={() => {
                      reOpenQuery(
                        query.id,
                        domain_id,
                        subject_id,
                        crf_id,
                        field_id,
                        newMessage,
                        location.pathname,
                        visit_index,
                      );
                    }}
                    sx={{ marginLeft: "10px" }}
                  >
                    Re-Open
                  </LoadingButton>
                ) : null}
                <LoadingButton
                  variant="standard"
                  loading={false}
                  onClick={closeNotificationWindow}
                  sx={{ marginLeft: "10px" }}
                >
                  Close
                </LoadingButton>
              </div>
            ) : null}
          </React.Fragment>
        </div>
      </div>
    </Dialog>
  );
}
