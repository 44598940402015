import React, { useState, useRef, useEffect } from "react";
import styles from "../decimal.module.css";
import { TextField } from "@mui/material";

import { cloneDeep } from "lodash";

export default function DecimalUI(props) {
  const {
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    length,
    units = "",
    placeHolder,
    whole_length = 2,
    decimal_length = 1,
    value_position = "value",
    value_name_position = "value_name",
    crfStatus,
  } = props;

  const [localVal, setLocalVal] = useState(null);
  const [oldValue, setOldValue] = useState(uval);
  const [decimalPosition, setDecimalPosition] = useState(2);
  const field1Ref = useRef(null);
  const field2Ref = useRef(null);
  const field3Ref = useRef(null);
  const field4Ref = useRef(null);
  const field5Ref = useRef(null);
  const field6Ref = useRef(null);
  const field7Ref = useRef(null);
  const [refList, setRefList] = useState([
    field1Ref,
    field2Ref,
    field3Ref,
    field4Ref,
    field5Ref,
    field6Ref,
    field7Ref,
  ]);

  const parseValue = (position) => {
    // let parsed = localVal.split("").filter((t) => t !== ".");
    let parsed = localVal.split("");
    let dotIndex = parsed.findIndex((b) => b === ".");
    let frontFill = whole_length - dotIndex;
    parsed = [...Array(frontFill).fill(""), ...parsed];
    parsed = parsed.filter((d) => d !== ".");

    if (parsed[position] === " ") {
      return "";
    } else return parsed[position] || "";
  };

  const parseFullValue = (value) => {
    let parsed = value.split("");
    let dotIndex = parsed.findIndex((b) => b === ".");
    let frontFill = whole_length - dotIndex;
    if (frontFill < 0) frontFill = 0;
    parsed = [...Array(frontFill).fill(""), ...parsed];
    // parsed = parsed.filter((d) => d !== ".");
    return parsed;
  };

  useEffect(() => {
    let value = parseFullValue(
      (field.value_obj && field.value_obj[value_position]) || ".",
    );
    setLocalVal(value);
  }, [field.value_obj]);

  const setValue = (newValue) => {
    setLocalVal(newValue);
  };

  const regex_test = (value) => {
    if (RegExp(/^[0-9]{1,5}[.]{1}[0-9]{1,3}$/).test(value)) {
      let split = value.split(".");
      if (
        split[0].length === whole_length &&
        split[1].length === decimal_length
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const saveValueIfValid = (value) => {
    let obj = { ...field.value_obj };
    obj[value_position] = value;
    obj[value_name_position] = field[value_name_position];
    if (regex_test(obj[value_position])) {
      onUpdate(obj);
    }
  };

  // const onKeyDown = (key) => {
  //   if (key.keyCode === 13) {
  //     ref.current.blur();
  //   }
  // };

  const onBlur = () => {
    let value = [...localVal];
    // let is_valid = true;
    // let hit_first_non_empty_character = false;
    // value.forEach((t, index) => {
    //   if (t === "" && !hit_first_non_empty_character) {
    //     value.splice(index, 1);
    //   } else if (t === "" && !hit_first_non_empty_character) {
    //     is_valid = false;
    //   } else if (t !== ".") {
    //     hit_first_non_empty_character = true;
    //   }
    // });
    if (regex_test(value.join(""))) {
      value = value.join("");
      saveValueIfValid(value);
    }
  };

  // useEffect(() => {
  //   let refs = [];
  //   for (let i = 0; i++; i < decimal_length) {
  //     refs.push(useRef(null));
  //     setRefList(refs);
  //   }
  // }, []);

  const onValueChange = (position, textValue, is_decimal = false) => {
    if (!/[0-9]/.test(textValue) && textValue !== "") return;
    let parsed = [...localVal];
    // let dotIndex = parsed.findIndex((b) => b === ".");
    // let frontFill = whole_length - dotIndex;
    // let backFill = decimal_length - (parsed.length - 1 - decimal_length);
    // if (backFill > 0) {
    //   parsed.concat([...Array(backFill).fill("")]);
    // }
    // parsed = [...Array(frontFill).fill(""), ...parsed];
    // parsed = parsed.filter((d) => d !== ".");

    // let assembled = localVal.split("").filter((t) => t !== ".");
    if (textValue) {
      parsed.splice(position, 1, textValue);
    } else {
      parsed.splice(position, 1, "");
    }
    // parsed.splice(decimalPosition, 0, ".");
    // parsed = parsed.join("");
    setLocalVal(parsed);
    if (textValue && crfStatus !== "data_complete") {
      setTimeout(() => {
        if (is_decimal) {
          refList[position].current.focus();
        } else {
          refList[position + 1].current.focus();
        }
      }, 100);
    }
  };
  if (!field.read_only) {
    return (
      <React.Fragment>
        <div className={styles.input_flex}>
          {Array.from(Array(whole_length).keys()).map((position) => (
            <div style={{ marginLeft: position === 0 ? 0 : 4 }} key={position}>
              <TextField
                inputRef={refList[position]}
                placeholder=""
                value={(localVal && localVal[position]) || ""}
                onChange={(e) => {
                  onValueChange(position, e.target.value);
                }}
                onBlur={onBlur}
                inputProps={{ maxLength: 1 }}
                autoComplete="off"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "24px",
                    fontSize: 14,
                  },
                }}
              />
            </div>
          ))}

          <div style={{ fontSize: 30 }}>.</div>
          {Array.from(Array(decimal_length).keys()).map((position) => (
            <div style={{ marginRight: 4 }}>
              <TextField
                inputRef={refList[position + whole_length]}
                placeholder=""
                value={
                  (localVal && localVal[whole_length + position + 1]) || ""
                }
                onChange={(e) => {
                  onValueChange(
                    position + whole_length + 1,
                    e.target.value,
                    true,
                  );
                }}
                onBlur={onBlur}
                inputProps={{ maxLength: 1 }}
                autoComplete="off"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "24px",
                    fontSize: 14,
                  },
                }}
              />
            </div>
          ))}
        </div>
        {units ? <div className={styles.input_description}>{units}</div> : null}
      </React.Fragment>
    );
  } else if (field.read_only) {
    return (
      <React.Fragment>
        <div>
          {field.value_obj[value_position]} {field.units || ""}
        </div>
      </React.Fragment>
    );
  }
}
