import React, { forwardRef } from "react";
import styles from "../subjects.module.css";

const AddSubjectMenu = (props, ref) => {
  const { router } = props;
  return (
    <div className={styles.hover_menu} ref={ref}>
      <div
        className={styles.menu_button}
        onClick={() => router.navigate("/select_domain")}
      >
        Add Subject to Trial
      </div>
    </div>
  );
};

export default forwardRef(AddSubjectMenu);
