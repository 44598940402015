import React, { useRef, useEffect, useState } from "react";
import { get } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./queries.module.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import QueryTable from "./components/query_table";

export default function Audit() {
  return (
    <div className={styles.flex}>
      <div className={styles.header}>
        <div>
          <h3>Queries</h3>
        </div>
        <div className={styles.search_wrapper}></div>
      </div>
      <QueryTable />
    </div>
  );
}
