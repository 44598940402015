import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./crf.module.css";
import { get, post } from "util/requests";
import RadioGroup from "ui/crfs/radio_group";
import Segment from "ui/crfs/segment";
import Year from "ui/crfs/year";
import Select from "ui/crfs/select";
import Time from "ui/crfs/time";
import Date from "ui/crfs/date";
import DropDown from "ui/crfs/drop_down";
import Text from "ui/crfs/text";
import Int from "ui/crfs/int";
import Decimal from "ui/crfs/decimal";
import BloodPressure from "ui/crfs/blood_pressure";
import DateTime from "ui/crfs/date_time";
import ConsentRecords from "../consent_records";
import Heading from "ui/crfs/heading";
import Weight from "ui/crfs/weight";
import BloodPressureDateTime from "ui/crfs/blood_pressure_date_time";
import BloodTestResult from "ui/crfs/blood_test_result";
import QaUI from "../qa_ui";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import router from "routes";
import { Dialog } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";

const arrayHaveCommonItems = (arr1, arr2) => {
  if (
    arr1 &&
    arr1.constructor === Array &&
    arr2 &&
    arr2.constructor === Array
  ) {
    return arr1.some((item) => arr2.includes(item));
  } else return false;
};

export default function CrfForm(props) {
  const {
    crf_id,
    subject_id,
    domain_id,
    visit_index,
    updateCrfQueryStatus,
    updateCrfStatus,
    form_type,
    multi_entry_crf_id,
    site_id,
  } = props;
  const location = useLocation();
  const [crf_data, setCrfData] = useState(null);
  const [crfStatus, setCrfStatus] = useState(null);
  const [crfSignStatus, setCrfSignStatus] = useState(null);
  const [
    showingDataCompleteMakeChangeModal,
    setShowingDataCompleteMakeChangeModal,
  ] = useState(false);

  const permissions = useSelector((state) => state.user.userData.permissions);
  const user_domains = useSelector((state) => state.user.userData.domains);
  const study_id = useSelector((state) => state.study.active_study_id);

  useEffect(() => {
    if (form_type === "multi_entry") {
      get(
        `/subject_mutli_entry_crf_data/${subject_id}/${props.crf_id}/${multi_entry_crf_id}`,
      ).then((ret) => {
        setCrfData(ret.data);
        setCrfStatus(ret.data.status || "");
        setCrfSignStatus(ret.data.qc_status || []);
        post("/footprint", {
          path: location.pathname,
          action: "pageload",
          name: `crf-${ret.data.name}`,
          subject_id,
          study_id,
          site_id,
        });
      });
    } else {
      get(`/subject_crf_data/${subject_id}/${props.crf_id}`)
        .then((ret) => {
          setCrfData(ret.data);
          setCrfStatus(ret.data.status || "");
          setCrfSignStatus(ret.data.qc_status || []);
          post("/footprint", {
            path: location.pathname,
            action: "pageload",
            name: `crf:${ret.data.name}`,
            subject_id,
            study_id,
            site_id,
          });
        })
        .catch(() => {
          router.navigate("/subjects");
        });
    }
  }, [crf_id, subject_id]);

  const setQuery = (crf_id, field_id, query) => {
    let data = cloneDeep(crf_data);
    let fields = data.design.fields;
    let field = fields.find((f) => f.id === field_id);
    field.query = query;
    setCrfData(data);
  };

  const updateFieldValue = (field_id, field_type) => (value_obj) => {
    post("/crf_data", {
      crf_id,
      field_id,
      value_obj,
      patient_id: subject_id,
      study_id: study_id,
      field_type,
      form_type,
      multi_entry_crf_id,
      pathname: location.pathname,
      visit_index: visit_index,
      domain_id,
      timezone: moment.tz.guess(),
      crf_slug: crf_data.slug,
      visit_index,
    }).then(({ data }) => {
      let _crf_data = cloneDeep(crf_data);
      let field = _crf_data.design.fields.find(
        (field) => field.id === field_id,
      );
      field.value_obj = value_obj;
      if (data.deviation_object && data.deviation_object.deviation === true) {
        field.value_obj.deviation = true;
      } else if (
        data.deviation_object &&
        data.deviation_object.violation === true
      ) {
        field.value_obj.violation = true;
      } else {
        delete field.value_obj.deviation;
        delete field.value_obj.violation;
      }
      setCrfData(_crf_data);
      setCrfSignStatus([]);
    });

    let _crf_id = form_type === "multi_entry" ? multi_entry_crf_id : crf_id;
    updateCrfStatus(_crf_id, "data").then((status) => {
      if (status) {
        setCrfStatus(status);
      }
      setCrfSignStatus([]);
    });
  };

  const renderFieldComponent = (field, field_array) => {
    const base_props = {
      field,
      key: field.id,
      subject_id: subject_id,
      crf_id: crf_id,
      field_id: field.id,
      options: field.options,
      title: field.label,
      uval: field.uval,
      query: field.query,
      setQuery: setQuery,
      domain_id: domain_id,
      visit_index: visit_index,
      calcUpdateToCrfStatus: updateCrfQueryStatus,
      enableQuery: true,
      form_type,
      multi_entry_crf_id,
      crf_name:
        (crf_data && crf_data.name && crf_data.name.toUpperCase()) || "",
      crfStatus,
      read_only: !crf_data.write_permission,
      crf_slug: crf_data.slug,
    };

    //check for subject randomization status against domains
    if (
      field.domains &&
      !arrayHaveCommonItems(field.domains, crf_data.randomization_domains)
    ) {
      return null;
    }
    //check for user access to domain assignment
    if (field.domains && !arrayHaveCommonItems(field.domains, user_domains)) {
      return null;
    }
    if (field.display_logic) {
      if (field.display_logic.field_id) {
        let compared_field = field_array.find(
          (f) => f.id === parseInt(field.display_logic.field_id),
        );
        if (field.display_logic.value) {
          if (compared_field.value_obj.value !== field.display_logic.value) {
            return null;
          }
        }
      }
    }
    if (field.type === "year") {
      return (
        <Year
          {...base_props}
          onUpdate={updateFieldValue(field.id, "year", field.value_name)}
        />
      );
    }
    if (field.type === "text") {
      return (
        <Text {...base_props} onUpdate={updateFieldValue(field.id, "text")} />
      );
    }
    if (field.type === "radio") {
      return (
        <RadioGroup
          {...base_props}
          onUpdate={updateFieldValue(field.id, "radio")}
          orientation={field.orientation || ""}
        />
      );
    }
    if (field.type === "select") {
      return (
        <Select
          {...base_props}
          onUpdate={updateFieldValue(field.id, "select")}
        />
      );
    }
    if (field.type === "time") {
      return (
        <Time {...base_props} onUpdate={updateFieldValue(field.id, "time")} />
      );
    }
    if (field.type === "date") {
      return (
        <Date {...base_props} onUpdate={updateFieldValue(field.id, "date")} />
      );
    }
    if (field.type === "datetime") {
      return (
        <DateTime
          {...base_props}
          onUpdate={updateFieldValue(field.id, "datetime")}
        />
      );
    }
    if (field.type === "dropdown") {
      return (
        <DropDown
          {...base_props}
          onUpdate={updateFieldValue(field.id, "dropdown")}
        />
      );
    }
    if (field.type === "int") {
      return (
        <Int
          {...base_props}
          length={field.max_length || 2}
          onUpdate={updateFieldValue(field.id, "int", field.value_name)}
          placeholder={field.placeHolder}
        />
      );
    }
    if (field.type === "weight") {
      return (
        <Weight
          {...base_props}
          length={field.max_length || 2}
          onUpdate={updateFieldValue(field.id, "weight")}
          placeholder={field.placeHolder}
        />
      );
    }
    if (field.type === "decimal") {
      return (
        <Decimal
          {...base_props}
          whole_length={field.whole_length}
          decimal_length={field.decimal_length}
          units={field.units}
          onUpdate={updateFieldValue(field.id, "decimal", field.value_name)}
        />
      );
    }
    if (field.type === "blood_pressure") {
      return (
        <BloodPressure
          {...base_props}
          onUpdate={updateFieldValue(field.id, "blood_pressure")}
        />
      );
    }
    if (field.type === "heading") {
      return (
        <Heading
          {...base_props}
          position={field.position}
          color={field.color}
          onUpdate={updateFieldValue(field.id, "blood_pressure")}
        />
      );
    }
    if (field.type === "blood_pressure_date_time") {
      return (
        <BloodPressureDateTime
          {...base_props}
          onUpdate={updateFieldValue(field.id, "blood_pressure_date_time")}
        />
      );
    }
    if (field.type === "blood_test_result") {
      return (
        <BloodTestResult
          {...base_props}
          onUpdate={updateFieldValue(field.id, "blood_test_result")}
        />
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.subject_title}>
        <strong> Subject: {props.subject_id}</strong>
      </div>
      {permissions.includes("crf_builder") ? (
        <div>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() => {
              router.navigate(`/crf_builder/${crf_id}`);
            }}
          >
            CRF Builder
          </LoadingButton>
        </div>
      ) : null}
      <div className={styles.segmentWrapper}>
        <Segment
          title={
            (crf_data && crf_data.name && crf_data.name.toUpperCase()) || ""
          }
          crf_id={form_type === "multi_entry" ? multi_entry_crf_id : crf_id}
          crf_data={crf_data}
          crf_status={crfStatus}
          crf_sign_status={crfSignStatus}
          subject_id={subject_id}
          updateCrfStatus={(crf_id, status) => {
            //this looks like some straight fuckery, but we maintain the status up a level so we can combine the actions on the crf with the actions on the crf list, like the setting of data state
            updateCrfStatus(crf_id, status).then((status) => {
              setCrfStatus(status);
            });
          }}
          updateCrfSignStatus={(crf_id, status) => {
            let _status = [...crfSignStatus];
            _status.push(status);
            setCrfSignStatus(_status);
          }}
        >
          {crf_data &&
            crf_data.design &&
            crf_data.design.fields.length &&
            crf_data.design.fields.map((field) =>
              renderFieldComponent(field, crf_data.design.fields),
            )}
        </Segment>
      </div>
    </div>
  );
}
