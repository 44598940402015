import styles from "./audit_components.module.css";

export default function RenderDataType(props) {
  const { json } = props;
  if (json.field_type === "date") {
    return (
      <div className={styles.row}>
        <div className={styles.element}>
          <span className={styles.strong}>Date:</span> {json.payload.value}
        </div>
      </div>
    );
  } else if (json.field_type === "time") {
    return (
      <div className={styles.row}>
        <div className={styles.element}>
          <span className={styles.strong}>Time: </span>
          {json.payload.value}
        </div>
      </div>
    );
  } else if (json.field_type === "radio") {
    return (
      <div className={styles.row}>
        <div className={styles.element}>
          <span className={styles.strong}>Type:</span> radio
        </div>
        <div className={styles.element}>
          <span className={styles.strong}>Selected:</span>{" "}
          {json.payload.currentValueDisplay}
        </div>{" "}
        <div className={styles.element}>
          <span className={styles.strong}>Stored:</span> {json.payload.value}
        </div>
      </div>
    );
  } else if (json.field_type === "select") {
    return (
      <div className={styles.row}>
        <div className={styles.element}>
          <span className={styles.strong}>Type:</span> select
        </div>
        <div className={styles.element}>
          <span className={styles.strong}>Selected:</span>{" "}
          {json.payload.currentValueDisplay}
        </div>{" "}
        <div className={styles.element}>
          <span className={styles.strong}>Stored:</span> {json.payload.value}
        </div>
      </div>
    );
  } else return <div>{JSON.stringify(json)}</div>;
}
