import React, { useState, useEffect } from "react";
import styles from "./user_profile.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Checkbox } from "@mui/material";
import Authentication from "./components/authentication";
import Notifications from "./components/notifications";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Form = (props) => {
  const { setEdit, user } = props;
  return (
    <div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Email:</div>
        <div>{user.email}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>First Name:</div>
        <div>{user.first_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Last Name:</div>
        <div>{user.last_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Timezone:</div>
        <div>{user.time_zone}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Phone:</div>
        <div>{user.phone}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Roles:</div>
        <div>{user.role && user.role.join(", ")}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Domains:</div>
        <div>{user.domains && user.domains.join(", ")}</div>
      </div>
      <LoadingButton
        variant="contained"
        loading={false}
        onClick={() => {
          setEdit(true);
        }}
      >
        EDIT
      </LoadingButton>
    </div>
  );
};

const EditForm = (props) => {
  const { user, setEdit, setUser } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [localUser, setLocalUser] = useState({
    time_zone: "",
    first_name: "",
    last_name: "",

    phone: "",
    email: "",
  });
  useEffect(() => {
    setLocalUser({ ...user });
  }, []);

  const setValue = (key, value) => {
    setLocalUser({ ...localUser, [key]: value });
  };

  // console.log(moment.tz.names().filter((t) => t.includes("Mountain")));

  return (
    <div>
      <div className={styles.profile_row}>
        <TextField
          disabled
          label="Email"
          placeholder="text"
          value={localUser.email}
          onChange={(e) => setValue("email", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="First Name"
          placeholder="text"
          value={localUser.first_name}
          onChange={(e) => setValue("first_name", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="Last Name"
          placeholder="text"
          value={localUser.last_name}
          onChange={(e) => setValue("last_name", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <Autocomplete
          value={localUser.time_zone}
          options={moment.tz.names()}
          size="small"
          sx={{
            "& .MuiAutocomplete-root": { fontSize: "10px" },
            fontSize: 12,
            height: 30,
            padding: 0,
            alignSelf: "center",
            width: 300,
          }}
          renderInput={(params) => <TextField {...params} label="Timezone" />}
          onChange={(e, value) => {
            setValue("time_zone", value);
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="Phone"
          placeholder="text"
          value={localUser.phone}
          onChange={(e) => setValue("phone", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <LoadingButton
        variant="contained"
        loading={isSaving}
        onClick={() => {
          setUser(localUser);
          // setEdit(false);
          setIsSaving(true);
          put("/user_details", { ...localUser }).then(() => {
            setIsSaving(false);
            setEdit(false);
          });
        }}
      >
        SAVE
      </LoadingButton>
      <LoadingButton
        variant="contained"
        color="inherit"
        sx={{ color: "#555" }}
        loading={false}
        onClick={() => {
          setEdit(false);
        }}
        style={{ marginLeft: 20 }}
      >
        CANCEL
      </LoadingButton>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className={styles.tab_panel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function UserProfile() {
  const [isEdit, setEdit] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
    time_zone: "",
    phone: "",
  });

  useEffect(() => {
    get("/user_details").then((ret) => setUser(ret.data));
  }, []);
  return (
    <div>
      <div className={styles.heading1}>User Settings</div>
      <Tabs
        value={activeTabIndex}
        onChange={(e, newValue) => setActiveTabIndex(newValue)}
        aria-label="User Settings Tabs"
      >
        <Tab
          label="Details"
          {...a11yProps(0)}
          sx={{
            "&.MuiTab-root": {},
            "&.Mui-selected": {
              borderBottom: "0px",
            },
          }}
        />
        <Tab
          label="Authentication"
          {...a11yProps(1)}
          sx={{
            "&.MuiTab-root": {},
            "&.Mui-selected": {},
          }}
        />
        <Tab label="Notifications" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={activeTabIndex} index={0}>
        {isEdit ? (
          <EditForm user={user} setEdit={setEdit} setUser={setUser} />
        ) : (
          <Form user={user} setEdit={setEdit} />
        )}
      </TabPanel>
      <TabPanel value={activeTabIndex} index={1}>
        <Authentication />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={2}>
        <Notifications />
      </TabPanel>
    </div>
  );
}
