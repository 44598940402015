import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import moment from "moment";

const columns = [
  {
    field: "patient_id",
    headerName: "Subject ID",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "is_sae",
    headerName: "SAE",
    width: 120,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "ae_code",
    headerName: "AE Code",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "ae_status_code",
    headerName: "AE Status Code",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "site_id",
    headerName: "Site ID",
    width: 150,
    align: "center",
    headerAlign: "center",
  },

  {
    field: "ae_date",
    headerName: "AE_Date",
    width: 160,
    renderCell: ({ row }) => {
      return moment(row.modified_on).format("yyyy-MMM-DD");
    },
  },
];

export default function AETable(props) {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    get("/ae").then((ret) => setEvents(ret.data));
  }, []);

  return (
    <DataGrid
      rows={events}
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={(data) => {
        // console.log("row", data.row);
        router.navigate(data.row.path);
      }}
    />
  );
}
