import { useEffect } from "react";
import { post } from "util/requests";
import router from "routes";
import styles from "./select_domain.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function SelectDomain(props) {
  const domains = useSelector((state) => state.user.userData.domains);
  const location = useLocation();

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "start_randomize",
    });
  }, [location.pathname]);

  return (
    <div>
      <div className={styles.title}>Select Study Domain</div>
      {domains.includes("bp") ? (
        <div
          className={styles.domain_card_wrapper}
          onClick={() => router.navigate("/eligibility/bp")}
        >
          Blood Pressure
        </div>
      ) : null}
      {domains.includes("actwhen") ? (
        <div
          className={styles.domain_card_wrapper}
          onClick={() => router.navigate("/eligibility/actwhen")}
        >
          Thrombolysis
        </div>
      ) : null}
      {domains.includes("act42") ? (
        <div
          className={styles.domain_card_wrapper}
          onClick={() => router.navigate("/eligibility/act42")}
        >
          ACT42
        </div>
      ) : null}
      {domains.includes("ecore") ? (
        <div
          className={styles.domain_card_wrapper}
          onClick={() => router.navigate("/eligibility/ecore")}
        >
          Escape Core
        </div>
      ) : null}
      {domains.length === 0 ? (
        <div>
          You don't have access to any study domains. Please contact
          support@actglobaltrial.com.
        </div>
      ) : null}
    </div>
  );
}
