import React, { useState } from "react";
import { Dialog } from "@mui/material";
import styles from "./confirm_consent_modal.module.css";
import main_styles from "../../randomize/randomize.module.css";
import SignaturePassword from "ui/crfs/signature_password";
import { Checkbox } from "@mui/material";
import DividerLine from "ui/crfs/divider_line";

export default function ConfirmConsentModal(props) {
  const {
    closeModal,
    subject_id,
    domain_name,
    showing,
    handleSubmitAction,
    data,
    questions,
  } = props;

  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");

  const renderData = (item) => {
    if (["int", "year"].includes(item.type)) {
      return (data[item.key] && data[item.key].value) || "";
    } else if (item.type === "datetime") {
      return `${(data[item.key] && data[item.key].value) || ""} ${
        (data[item.key] && data[item.key].value2) || ""
      } `;
    } else if (item.type === "radio") {
      if (data[item.key] && data[item.key].value) {
        return questions
          .find((q) => q.key === item.key)
          .options.find((o) => o.value === data[item.key].value).label;
      }
    } else if (item.type === "weight") {
      if (data[item.key]) {
        let r = data[item.key];
        return `${r.value} ${r.value2} (${r.value3})`;
      } else {
        return "";
      }
    } else if (item.type === "blood_pressure") {
      if (data[item.key]) {
        let r = data[item.key];
        return `${r.value}/${r.value2} mmHg`;
      } else {
        return "";
      }
    } else {
      return "todo";
    }
  };

  const renderQuestion = (item) => {
    return (
      <React.Fragment>
        <div className={styles.row}>
          <div className={styles.question}>{item.text} </div>
          <div>
            <strong>{renderData(item)}</strong>
          </div>
        </div>
        <DividerLine />
      </React.Fragment>
    );
  };

  const disabled = password.length < 8 || !confirmation;

  return (
    <Dialog open={showing} onClose={closeModal}>
      <div className={styles.hover_menu}>
        <div className={styles.header}>
          <div className={main_styles.title}>ECORE - Confirm Enrollment</div>
        </div>

        <div
          className={styles.confirmation}
          onClick={() => setConfirmation(!confirmation)}
        >
          <Checkbox checked={confirmation} className={styles.checkbox} />
          <div>
            I CONFIRM that the subject meets eligibility criteria, the consent
            process has been completed according to local requirements, and is
            ready to be enrolled.
          </div>
        </div>
        <div className={styles.password_wrapper}>
          <SignaturePassword
            submit_text={"SUBMIT"}
            onSubmit={(password) => {
              //this returns to the signature form to set button state

              return new Promise((resolve) => {
                handleSubmitAction({
                  password,
                  modal_confirmation: confirmation,
                }).then(({ success }) => {
                  resolve(success);
                });
              });
            }}
            disabled={disabled}
            password={password}
            setPassword={setPassword}
          />
        </div>
      </div>
    </Dialog>
  );
}
