import React, { useState, useRef, useEffect } from "react";
import styles from "../date.module.css";
import { TextField } from "@mui/material";

export default function YearUI(props) {
  const {
    key_id,
    field,
    title,
    onUpdate,
    enableQuery,
    length = 4,
    placeHolder,
    value_position = "value",
    value_name_position = "value_name",
    item_specific_error_validator,
    setParentFieldError,
  } = props;

  const ref = useRef(null);
  const [localVal, setLocalVal] = useState(field.value_obj[value_position]);
  const [lengthErrorFlag, setLengthErrorFlag] = useState(false); // used to keep track of if we should flag for error if less than 4

  useEffect(() => {
    setLocalVal(field.value_obj[value_position]);
  }, [field.value_obj]);

  const setValue = (newValue) => {
    setLocalVal(newValue);
  };

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      ref.current.blur();
    }
  };

  const onBlur = () => {
    onUpdate(localVal);
  };

  const is_error = () => {
    if (localVal.length !== 4 && !lengthErrorFlag) {
      return false;
    } else {
      let local_validation = () => !/^[1-2]{1}[0-9]{3}$/.test(localVal);
      let error = local_validation();
      if (
        item_specific_error_validator &&
        item_specific_error_validator.constructor === Function
      ) {
        error = local_validation() || item_specific_error_validator(localVal);
      }

      setParentFieldError && setParentFieldError(key_id, error);
      return error;
    }
  };

  return (
    <React.Fragment>
      <div className={styles.input_flex}>
        <div>
          <TextField
            type="text"
            inputRef={ref}
            placeholder=""
            autoComplete="off"
            inputProps={{ maxLength: length }}
            value={localVal}
            onChange={(e) => {
              if (/^([0-9]+){0,4}$/.test(e.target.value)) {
                if (localVal.length === 4 && e.target.value.length === 3) {
                  setLengthErrorFlag(true);
                }
                setValue(e.target.value);
              }
            }}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            sx={{
              "& .MuiInputBase-input": {
                padding: "5px",
                textAlign: "center",
                width: "50px",
              },
            }}
            error={is_error()}
          />
        </div>
        <div className={styles.input_description}>(YYYY)</div>
      </div>
    </React.Fragment>
  );
}
