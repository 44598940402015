import styles from "./audit_components.module.css";

export default function RenderPageLoad(props) {
  let { json } = props;

  return (
    <div className={styles.row}>
      <div className={styles.element}>
        <span className={styles.strong}>Name:</span> {json.name}
      </div>{" "}
      <span className={styles.strong}>Path:</span>{" "}
      <span className={styles.path}>{json.path}</span>
    </div>
  );
}
