import React, { useRef } from "react";
import styles from "./electronic_consent_form.module.css";
import { TextField } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { Checkbox } from "@mui/material";
import ConsentContent from "./consent_content";

export default function ElectronicConsentForm() {
  return (
    <div className={styles.wrapper}>
      <ConsentContent />
    </div>
  );
}
