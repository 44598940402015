import React, { useState } from "react";
import styles from "../user_role_modal/user_role_modal.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, Checkbox } from "@mui/material";

export default function UserSiteModal(props) {
  const {
    activeSites,
    siteList,
    siteSelectIsOpen,
    setSiteSelectIsOpen,
    updateParent,
    user,
  } = props;
  const [sites, setSites] = useState(
    (activeSites && activeSites.map((r) => r.id)) || [],
  );

  const updateSites = (site_id, checked) => {
    let updated_sites = [...sites];
    if (checked) {
      updated_sites.push(site_id);
    } else {
      updated_sites = updated_sites.filter((id) => id !== site_id);
    }
    setSites(updated_sites);
  };

  return (
    <Dialog open={siteSelectIsOpen} onClose={() => setSiteSelectIsOpen(false)}>
      <div className={styles.dialog_wrapper}>
        <div className={styles.roles_dialog_title}>
          <strong>
            Site Access: {user.first_name} {user.last_name}
          </strong>
        </div>
        <div className={styles.role_wrapper}>
          {siteList.map((site) => (
            <div className={styles.role_row}>
              <Checkbox
                key={site.id}
                checked={sites.includes(site.id)}
                className={styles.checkbox}
                onChange={(e) => {
                  updateSites(site.id, e.target.checked);
                }}
              />
              <div>
                {site.name} {` (${site.code})`}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.dialog_button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              updateParent(sites);
              setSiteSelectIsOpen(false);
            }}
          >
            UPDATE
          </LoadingButton>
          <LoadingButton
            variant="standard"
            loading={false}
            onClick={() => {
              setSiteSelectIsOpen(false);
            }}
          >
            CANCEL
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
