import React, { useState } from "react";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import styles from "./button_to_modal.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "@mui/material/Modal";

export default function ButtonToModal(props) {
  const {
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    content,
    disabled,
    enableQuery,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget title={title} />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>
        <div className={[styles["right-split"]]}>
          <div>
            <div className={styles.button_wrapper}>
              <LoadingButton
                disabled={disabled}
                variant="contained"
                loading={false}
                onClick={() => setIsOpen(true)}
              >
                OPEN
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={styles.modal_wrapper}>{content}</div>
      </Modal>

      <DividerLine />
    </div>
  );
}
