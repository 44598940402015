import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import moment from "moment";

const columns = [
  {
    field: "vial_number",
    headerName: "Vial #",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "subject_id",
    headerName: "Subject ID",
    width: 120,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "expiry_date",
    headerName: "Expiry Date",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => {
      return moment(row.expiry_date).format("yyyy-MMM-DD");
    },
  },
  {
    field: "status",
    headerName: "Drug Status",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "location",
    headerName: "Location",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "location_status",
    headerName: "Location Status",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "modified_on",
    headerName: "Modified",
    width: 160,
    renderCell: ({ row }) => {
      return moment(row.modified_on).format("yyyy-MMM-DD HH:mm:ss");
    },
  },
];

export default function DrugTable(props) {
  const [queries, setQueries] = useState([]);

  useEffect(() => {
    get("/drugs").then((ret) => setQueries(ret.data));
  }, []);

  return (
    <DataGrid
      rows={queries}
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 12,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={(row) => {
        router.navigate(`/study/1/site/1/subjects/${row.id}/view`);
      }}
    />
  );
}
