import React, { useState } from "react";
import styles from "../../randomize.module.css";
import { TextField, Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DividerLine from "ui/crfs/divider_line";
import CheckIcon from "@mui/icons-material/Check";
import { post } from "util/requests";

export default function EmailConsent(props) {
  const { onComplete } = props;
  const [complete, setComplete] = useState(false);
  const [email, setEmail] = useState("");
  const [consentChecked, setConsentChecked] = useState(false);

  const handleEmailSend = () => {
    post("consent_email", { email });
    setComplete(true);
    onComplete();
  };

  const emailvalid = (value) =>
    value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
      ? true
      : false;

  const is_disabled = !consentChecked || !emailvalid(email);

  if (complete) {
    return (
      <div className={styles.econsent_success_wrapper}>
        <div>Email sent to {email}</div>
        <div className={styles.check_mark}>
          <CheckIcon sx={{ color: "green" }} />
        </div>
      </div>
    );
  } else
    return (
      <div>
        <div>
          <Checkbox
            key={1}
            checked={consentChecked}
            className={styles.checkbox}
            onChange={(e) => setConsentChecked(e.target.checked)}
          />
          I consent to an email being sent to:
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            value={email}
            type="email"
            placeholder="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "5px",
                textAlign: "center",
              },
            }}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        >
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={handleEmailSend}
            disabled={is_disabled}
          >
            Send Email
          </LoadingButton>
        </div>
        <DividerLine />
      </div>
    );
}
