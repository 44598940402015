import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import styles from "../../electronic_consent_form.module.css";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Signature(props) {
  const { label } = props;
  const signatureRef = useRef();
  return (
    <React.Fragment>
      <div className={styles.text_field_title}>{label}</div>
      <div className={styles.sigWrapper}>
        <div className={styles.sigPad}>
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{ className: styles.sigPad }}
          />
        </div>
      </div>
      <LoadingButton
        variant="inherit"
        loading={false}
        onClick={() => signatureRef.current.clear()}
        sx={{ backgroundColor: "#ddd", height: 34, width: 40 }}
      >
        Clear
      </LoadingButton>
    </React.Fragment>
  );
}
