import React, { useState } from "react";
import styles from "../user_role_modal/user_role_modal.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, Checkbox } from "@mui/material";

export default function UserDomainModal(props) {
  const {
    activeDomains,
    domainList,
    roleSelectIsOpen,
    setRoleSelectIsOpen,
    updateParent,
    user,
  } = props;
  const [domains, setDomains] = useState(
    (activeDomains && activeDomains.map((r) => r.id)) || [],
  );

  const updateDomains = (domain_id, checked) => {
    let updated_roles = [...domains];
    if (checked) {
      updated_roles.push(domain_id);
    } else {
      updated_roles = updated_roles.filter((id) => id !== domain_id);
    }
    setDomains(updated_roles);
  };

  return (
    <Dialog open={roleSelectIsOpen} onClose={() => setRoleSelectIsOpen(false)}>
      <div className={styles.dialog_wrapper}>
        <div className={styles.roles_dialog_title}>
          <strong>
            Domains: {user.first_name} {user.last_name}
          </strong>
        </div>
        <div className={styles.role_wrapper}>
          {domainList.map((domain) => (
            <div className={styles.role_row}>
              <Checkbox
                key={domain.id}
                checked={domains.includes(domain.id)}
                className={styles.checkbox}
                onChange={(e) => {
                  updateDomains(domain.id, e.target.checked);
                }}
              />
              <div>{domain.name}</div>
            </div>
          ))}
        </div>
        <div className={styles.dialog_button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              updateParent(domains);
              setRoleSelectIsOpen(false);
            }}
          >
            UPDATE
          </LoadingButton>
          <LoadingButton
            variant="standard"
            loading={false}
            onClick={() => {
              setRoleSelectIsOpen(false);
            }}
          >
            CANCEL
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
