import React from "react";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import styles from "./dropdown.module.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";

export default function DropDown(props) {
  const {
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    length,
    units,
    placeHolder,
    disabled,
  } = props;

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget {...props} />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>
        <div
          className={[styles["right-split"], styles.dropdown_entry].join(" ")}
        >
          <div>
            <Select
              disabled={disabled}
              value={uval}
              sx={{
                fontSize: 14,
                minWidth: 200,
                height: 30,
                padding: 0,
                alignSelf: "center",
              }}
              onChange={(e) => {
                onUpdate(e.target.value);
              }}
            >
              {options &&
                options.length &&
                options.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
            </Select>
          </div>
          {show_checkmark ? (
            <div className={styles.check_mark}>
              <CheckIcon sx={{ color: "green" }} />
            </div>
          ) : null}
        </div>
      </div>

      <DividerLine />
    </div>
  );
}
