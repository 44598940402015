import { useEffect, useState } from "react";
import styles from "./data_complete_change_modal.module.css";
import { get, post } from "util/requests";

import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import router from "routes";
import { Dialog } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function DataCompleteChangeModal(props) {
  const { isOpen, close, onYes } = props;

  return (
    <Dialog open={isOpen} onClose={close}>
      <div className={styles.modal_wrapper}>
        <div className={styles.modal_title}>Update Completed Data</div>
        <div className={styles.modal_body}>
          <div>The data entry for this CRF has been set to complete.</div>
          <div>Are you sure you want to make this update?</div>
        </div>
        <div className={styles.modal_button_wrapper}>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={onYes}
          >
            YES
          </LoadingButton>
          <LoadingButton
            disabled={false}
            variant="outlined"
            loading={false}
            onClick={close}
          >
            NO
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
