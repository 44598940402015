import { Fragment, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PasswordResetForm from "ui/password_reset_form";
import styles from "./password_reset_authenticated.module.css";
import router from "routes";

export default function PasswordResetAuthenticated(props) {
  const [isComplete, setIsComplete] = useState(false);

  return (
    <div>
      {!isComplete ? (
        <Fragment>
          <PasswordResetForm onComplete={() => setIsComplete(true)} />
          <div
            className={styles.cancel_button}
            onClick={() => {
              router.navigate("/user_profile");
            }}
          >
            Cancel
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={styles.complete_message}>
            Your password has been successfully reset.
          </div>
          <LoadingButton
            variant="contained"
            onClick={() => {
              router.navigate("/user_profile");
            }}
          >
            Go Back
          </LoadingButton>
        </Fragment>
      )}
    </div>
  );
}
