import React, { Component, useEffect, useState } from "react";
import { get, post } from "util/requests";
import styles from "./visit_map.module.css";
import CheckBoxIcon from "@mui/icons-material/TaskAlt";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SmsOutlined from "@mui/icons-material/SmsOutlined";
import Tooltip from "@mui/material/Tooltip";
import {
  NotifyIcon,
  ResolvedIcon,
  RespondedIcon,
  CheckIcon,
  DataIcon,
} from "ui/icons";
import { useNavigate, useLocation } from "react-router-dom";

const renderIcon = (index, row_obj) => {
  let ret = [];
  let status = (row_obj && row_obj.status) || null;
  let query_status = (row_obj && row_obj.query_status) || null;

  if (query_status === "closed") {
    ret.push(
      <Tooltip title="All Queries Resolved" key={0}>
        <ResolvedIcon fontSize="18px" />
      </Tooltip>,
    );
  } else if (query_status === "action") {
    ret.push(
      <Tooltip title="Queries With Response Needed" key={1}>
        <NotifyIcon fontSize="18px" />
      </Tooltip>,
    );
  } else if (query_status === "okay") {
    ret.push(
      <Tooltip title="Open Queries, No Action Needed" key={6}>
        <RespondedIcon fontSize="18px" />
      </Tooltip>,
    );
  }

  if (index === 0) return (row_obj && row_obj.name) || null;
  else if (status === "checked") {
    ret.push(
      <CheckBoxIcon
        key={2}
        sx={{ color: "#10944f", fontSize: "20px", lineHeight: "16px" }}
      />,
    );
  } else if (status === "available") {
    ret.push(
      <CheckBoxOutlineBlankIcon
        key={3}
        sx={{ color: "#888", fontSize: "20px", lineHeight: "16px" }}
      />,
    );
  } else if (status === "data") {
    ret.push(
      <Tooltip title="Data Partially Complete" key={4}>
        <DataIcon fontSize={"19px"} />
      </Tooltip>,
    );
  } else if (status === "data_complete") {
    ret.push(
      <Tooltip title="Data Flagged As Complete" key={5}>
        <CheckIcon fontSize={"18px"} />
      </Tooltip>,
    );
  }

  return <div>{ret}</div>;
};

const rowIterater = (length = 7) => {
  return Array.from(Array(length).keys());
};

const get_row_item_crf = (row, current_column_index) => {
  if (current_column_index === 0) {
    return row;
  } else {
    return row.visits[current_column_index];
  }
};

const get_row_item_global_form = (row, current_column_index) => {
  if (current_column_index === 0) {
    return row;
  } else {
    return {
      status:
        (row.on_visits.find((v) => v.visit === current_column_index) &&
          (row.status || "available")) ||
        "no",
    };
  }
};

const get_leftmost_item_style = (index) => {
  let style = [];
  if (index === 0) {
    style = [styles.leftmost_item, styles.white_background];
  } else {
    style = [styles.item, styles.white_background];
  }
  return style.join(" ");
};

const get_header_item_style = (index) => {
  let style = [];
  if (index === 0) {
    style = [
      styles.leftmost_item,
      styles.white_background,
      styles.header_height,
    ];
  } else {
    style = [
      styles.item,
      styles.white_background,
      styles.grey_background,
      styles.header_height,
    ];
  }
  return style.join(" ");
};

const create_headings = (data) => {
  const headings1 = Array.from(Array(7).keys()).map((index) => {
    if (index === 0) {
      return "";
    } else {
      return (data[index] && data[index].name) || "";
    }
  });
  const headings2 = Array.from(Array(7).keys()).map((index) => {
    if (index === 0) {
      return "";
    } else {
      return (data[index] && data[index].milestone) || "";
    }
  });
  const headings3 = Array.from(Array(7).keys()).map((index) => {
    if (index === 0) {
      return "";
    } else {
      return (data[index] && data[index].description) || "";
    }
  });
  return { headings1, headings2, headings3 };
};

export default function VisitMap(props) {
  const navigate = useNavigate();
  const { subject_id } = props;
  const [data, setData] = useState({ visits: {}, crfs: [], global_forms: [] });
  const [ready, setReady] = useState(false);
  const location = useLocation();

  useEffect(() => {
    get(`/patient_crf_status/${subject_id}`).then(({ data }) => {
      if (data.status === "no_domain_access") {
        setReady(data.status);
      } else if (data) {
        setData(data);
        setReady(true);
      }
    });
  }, [subject_id]);

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "visit_map",
      subject_id,
    });
  }, [location.pathname]);

  if (!ready) {
    return null;
  } else if (ready === "no_domain_access") {
    return (
      <div className={styles.wrapper}>
        <div>You do not have access to this subject.</div>
      </div>
    );
  }
  const { headings1, headings2, headings3 } = create_headings(data.visits);
  return (
    <div className={styles.wrapper}>
      <div className={styles.grid_row}>
        {headings1.map((heading, index) => (
          <div
            className={get_header_item_style(index)}
            key={(heading && heading + index) || crypto.randomUUID()}
          >
            <strong>{heading}</strong>
          </div>
        ))}
      </div>
      <div className={styles.grid_row}>
        {headings2.map((heading, index) => (
          <div
            className={get_header_item_style(index)}
            key={(heading && heading + index) || crypto.randomUUID()}
          >
            <strong>{heading}</strong>
          </div>
        ))}
      </div>
      <div className={styles.grid_row}>
        {headings3.map((heading, index) => (
          <div
            className={get_header_item_style(index)}
            key={(heading && heading + index) || crypto.randomUUID()}
          >
            {heading}
          </div>
        ))}
      </div>
      <div>
        {data.crfs
          .filter(({ category }) => category === "main")
          .map((row) => (
            <div
              className={styles.grid_row}
              key={row.name || crypto.randomUUID()}
            >
              {rowIterater().map((index) => (
                <div
                  key={`${row.name}-${index}`}
                  className={index === 0 ? styles.leftmost_item : styles.item}
                  onClick={() =>
                    navigate(
                      `/study/1/site/1/subjects/${subject_id}/edc/visit/${index}/crf/${row.visits[index].id}`,
                    )
                  }
                >
                  {renderIcon(index, get_row_item_crf(row, index))}
                </div>
              ))}
            </div>
          ))}
      </div>
      {data.crfs.filter(
        ({ category }) => category === "study_level_single_entry",
      ).length ? (
        <React.Fragment>
          <div className={styles.log_forms_title}>
            Study Level Single Entry Forms
          </div>
          <div>
            {data.crfs
              .filter(({ category }) => category === "study_level_single_entry")
              .map((row) => (
                <div
                  className={styles.grid_row}
                  key={row.name || crypto.randomUUID()}
                >
                  {rowIterater().map((index) => (
                    <div
                      key={`${row.name}-${index}`}
                      className={
                        index === 0 ? styles.leftmost_item : styles.item
                      }
                      onClick={() =>
                        navigate(
                          `/study/1/site/1/subjects/${subject_id}/edc/visit/${index}/crf/${row.visits[index].id}`,
                        )
                      }
                    >
                      {renderIcon(index, get_row_item_crf(row, index))}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </React.Fragment>
      ) : null}
      {data.global_forms.length ? (
        <React.Fragment>
          <div className={styles.log_forms_title}>
            Study Level Multiple Entry Forms
          </div>
          <div>
            {data.global_forms.map((row) => (
              <div
                className={styles.grid_row}
                key={row.name || crypto.randomUUID()}
              >
                {rowIterater().map((index) => (
                  <div
                    key={`${row.name}-${index}`}
                    className={index === 0 ? styles.leftmost_item : styles.item}
                    onClick={() =>
                      navigate(
                        `/study/1/site/1/subjects/${subject_id}/edc/visit/${index}/multi_entry/${row.id}`,
                      )
                    }
                  >
                    {renderIcon(index, get_row_item_global_form(row, index))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}
