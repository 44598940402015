import React, { useState, useRef, useEffect } from "react";
import styles from "../int.module.css";
import { TextField } from "@mui/material";

export default function IntUI(props) {
  const {
    key_id,
    field,
    title,
    onUpdate,
    enableQuery,
    length,
    placeHolder,
    value_position = "value",
    value_name_position = "value_name",
    units = "",
    item_specific_error_validator,
    setParentFieldError,
  } = props;

  const [localVal, setLocalVal] = useState(
    (field && field.value_obj && field.value_obj[value_position]) || "",
  );

  useEffect(() => {
    if (field && field.value_obj && field.value_obj[value_position] === 0) {
      setLocalVal(0);
    } else {
      setLocalVal(
        (field && field.value_obj && field.value_obj[value_position]) || "",
      );
    }
  }, [field.value_obj]);

  const ref = useRef(null);

  const setValue = (newValue) => {
    setLocalVal(newValue);
  };

  const local_validation = (value) => /^[0-9]{1,12}$/.test(value);

  const updateValue = () => {
    let obj = { ...field.value_obj };
    if (local_validation(localVal)) {
      obj[value_position] = parseInt(localVal);
    } else {
      obj[value_position] = "";
    }

    obj[value_name_position] = field[value_name_position];
    onUpdate(obj);
  };

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      ref.current.blur();
    }
  };

  const onBlur = () => {
    updateValue();
  };

  const is_error = (value) => {
    if (value.length === 0) {
      return false;
    } else {
      let error = !local_validation(value);
      if (
        item_specific_error_validator &&
        item_specific_error_validator.constructor === Function
      ) {
        error = local_validation() || item_specific_error_validator(value);
      }

      // setParentFieldError && setParentFieldError(key, error);
      return error;
    }
  };

  return (
    <React.Fragment>
      <div>
        <TextField
          type="text"
          inputRef={ref}
          placeholder={placeHolder || ""}
          value={localVal}
          onChange={(e) => {
            let value = e.target.value;
            console.log(value);
            if (/^([0-9]+){0,4}$/.test(value)) {
              setValue(value);
            }
            if (is_error(value)) {
              setParentFieldError && setParentFieldError(key_id, true);
            } else {
              setParentFieldError && setParentFieldError(key_id, false);
            }
          }}
          inputProps={{ maxLength: length }}
          autoComplete="off"
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              textAlign: "center",
              width: `${length * 10 + 10}px`,
              fontSize: 14,
            },
          }}
          error={is_error(localVal)}
        />
      </div>
      {units ? <div className={styles.input_description}>{units}</div> : null}
    </React.Fragment>
  );
}
