import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthState } from "redux/auth/actions";
import { setUserData } from "redux/user/actions";
import { setActiveStudyId } from "redux/study/actions";

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { login } from "util/auth";
import "./index.css";
import act_global_logo from "assets/act_global_logo.png";
import ctms_logo from "assets/ctms_logo.png";
import styles from "./index.module.css";
import router from "routes";
import { useStartTimer } from "timer";

const error_message = "Sorry, your login was not correct.";

const logo = window.location.hostname.includes("actglobaltrial.com")
  ? act_global_logo
  : ctms_logo;

export default function SignIn() {
  const [is_loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showEmail2fa, setShowEmail2fa] = useState(false);
  const [showOTP2fa, setShowOTP2fa] = useState(false);
  const [email, setEmail] = useState("");
  const [_2faCode, set2faCode] = useState("");
  const [googleError, setGoogleError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const start_timer = useStartTimer();

  const handleLogin = (force_email = false) => {
    if (is_loading) return;
    setIsLoading(true);
    if (_2faCode && _2faCode.length !== 6) {
      setGoogleError("Code is the wrong length.");
      setIsLoading(false);
      return;
    }
    login(
      email,
      password,
      _2faCode || null,
      showEmail2fa || force_email ? "email" : "otp",
    )
      .then((ret) => {
        if (ret.data.enable_2fa) {
          if (ret.data.email_2fa) {
            setShowEmail2fa(true);
          } else if (ret.data.google_2fa) {
            setShowOTP2fa(true);
          }
          setIsLoading(false);
        } else {
          dispatch(setAuthState(true));
          if (ret.data.user_data) {
            dispatch(setUserData(ret.data.user_data));
          }
          if (ret.data.user_data && ret.data.user_data.preferences) {
            let study_id_pref = ret.data.user_data.preferences.find(
              (p) => p.name === "default_study_id",
            );
            if (study_id_pref) {
              dispatch(setActiveStudyId(study_id_pref.value));
              start_timer();
              navigate({
                pathname: "/subjects",
                search: `sid=${study_id_pref.value}`,
              });
            } else {
              dispatch(setActiveStudyId(1));
              start_timer();
              navigate("/subjects");
            }
          }

          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response.data &&
          err.response.data.message &&
          err.response.data.message === "attempt_limit"
        ) {
          setErrorMessage(
            `To many failed attempts. Please try again in ${err.response.data.retry || 5} minutes, or reset your password.`,
          );
          setPassword("");
          return;
        }

        if (!showEmail2fa) {
          setPassword("");
        } else {
          setGoogleError("Invalid code, please try again.");
        }
        setErrorMessage(error_message);
      });
  };

  const handleCancel2fa = () => {
    setShowEmail2fa(false);
    setShowOTP2fa(false);
    setErrorMessage("");
    setPassword("");
    set2faCode(null);
    setGoogleError(null);
  };

  const handleFieldChange = (key) => (e) => {
    if (key === "email") setEmail(e.target.value);
    else if (key === "password") setPassword(e.target.value);
    setErrorMessage(null);
  };

  const onKeyDown = (key) => {
    if (key.key === "Enter") {
      handleLogin();
    }
  };

  const onKeyDown2fa = (key) => {
    if (key.key === "Enter") {
      handleLogin();
    }
  };

  const fallBacktoEmail2fa = () => {
    setShowOTP2fa(false);
    handleLogin(true);
  };

  return (
    <div className="wrapper">
      {!showEmail2fa && !showOTP2fa ? (
        <div className="login-card">
          <img src={logo} width="400px" className="logo" />
          <div className="margin-b-1">
            <TextField
              label="Email"
              variant="standard"
              onChange={handleFieldChange("email")}
              onKeyDown={onKeyDown}
              inputProps={{ style: { textTransform: "lowercase" } }}
            />
          </div>
          <div className="margin-b-2">
            <TextField
              id="standard-basic"
              label="Password"
              variant="standard"
              type="password"
              onChange={handleFieldChange("password")}
              onKeyDown={onKeyDown}
              value={password}
            />
          </div>
          <LoadingButton
            variant="contained"
            loading={is_loading}
            onClick={handleLogin}
          >
            SIGN IN
          </LoadingButton>
          {errorMessage ? (
            <div className={styles.error_message}>{errorMessage}</div>
          ) : null}
          <div
            className={styles.forgot_password}
            onClick={() => router.navigate("/password_reset_sent")}
          >
            Forgot password?
          </div>
          {window.location.hostname.includes("test.platform") ? (
            <div className={styles.staging_text}>(STAGING)</div>
          ) : null}
          {window.location.hostname.includes("localhost") ? (
            <div className={styles.staging_text}>(DEV)</div>
          ) : null}
        </div>
      ) : null}
      {showEmail2fa ? (
        <div className="login-card">
          <img src={logo} className="logo" />
          {/*<div className={styles._2fa_message}>
            Please enter your One-Time-Passcode code.
          </div>*/}
          <div className={styles._2fa_message}>
            Please enter the 6-digit code you were just emailed.
          </div>
          <div>
            <TextField
              placeholder=""
              value={_2faCode}
              onChange={(e) => set2faCode(e.target.value)}
              autoComplete="off"
              onKeyDown={onKeyDown2fa}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "100px",
                },
              }}
            />
          </div>
          <div className={styles._2fa_button_wrapper}>
            <LoadingButton
              variant="contained"
              loading={is_loading}
              onClick={handleLogin}
              className={styles._2fa_button}
            >
              SUBMIT
            </LoadingButton>
            {googleError ? (
              <div className={styles._2fa_error}>{googleError}</div>
            ) : null}
          </div>
          <div className={styles.cancel_button} onClick={handleCancel2fa}>
            Cancel
          </div>
        </div>
      ) : null}
      {showOTP2fa ? (
        <div className="login-card">
          <img src={logo} className="logo" />
          <div className={styles._2fa_message}>
            Please enter your One-Time-Passcode code.
          </div>

          <div>
            <TextField
              placeholder=""
              value={_2faCode}
              onChange={(e) => set2faCode(e.target.value)}
              autoComplete="off"
              onKeyDown={onKeyDown2fa}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "100px",
                },
              }}
            />
          </div>
          <div className={styles._2fa_button_wrapper}>
            <LoadingButton
              variant="contained"
              loading={is_loading}
              onClick={handleLogin}
              className={styles._2fa_button}
            >
              SUBMIT
            </LoadingButton>
            {googleError ? (
              <div className={styles._2fa_error}>{googleError}</div>
            ) : null}
          </div>
          <div className={styles.try_another} onClick={fallBacktoEmail2fa}>
            Try another way
          </div>
          <div className={styles.cancel_button} onClick={handleCancel2fa}>
            Cancel
          </div>
        </div>
      ) : null}
    </div>
  );
}
