export const common_questions = [
  {
    key: "date_known_well",
    text: "Date & Time of symptom onset/last known well:",
    type: "datetime",
    value_name: "ur06_date",
    value2_name: "ur06_time",
    crf_id: "5332e365-9dc8-4c2b-b608-ca4900395235",
    crf_field_id: 1,
    validation: { disableFuture: true },
    item_specific_error_validator: (valueObj) => {
      //returns true on error
      let time = valueObj.value2;
      if (
        parseInt(time.split(":")[0]) >= 24 ||
        parseInt(time.split(":")[1]) >= 60
      ) {
        return true;
      }
      return false;
    },
  },
  {
    key: "yob",
    text: "Subject Year of Birth:",
    type: "year",
    value_name: "ur06_yob",
    crf_id: "5332e365-9dc8-4c2b-b608-ca4900395235",
    crf_field_id: 2,
    item_specific_error_validator: (value) => {
      //returns true on error
      if (value.length !== 4) {
        return true;
      }
      let now = new Date();
      let now_year = now.getFullYear();
      value = parseInt(value);
      let age = now_year - value;
      if (age <= 17 || age > 105) {
        return true;
      } else return false;
    },
  },
  {
    key: "sex",
    text: "Subject Sex:",
    type: "radio",
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ],
    value_name: "ur06_sex",
    crf_id: "5332e365-9dc8-4c2b-b608-ca4900395235",
    crf_field_id: 3,
  },
  {
    key: "weight",
    text: "Subject Weight:",
    type: "weight",
    value_name: "ur06_weightVal",
    value2_name: "ur06_weightUnit",
    value3_name: "ur06_weightMeasure",
    crf_id: "5332e365-9dc8-4c2b-b608-ca4900395235",
    crf_field_id: 4,
    item_specific_error_validator: (valueObj) => {
      //returns true on error
      let value = valueObj.value && parseInt(valueObj.value);
      if (valueObj.value2 === "kg" && (value < 40 || value > 140)) {
        return true;
      } else if (valueObj.value2 === "lbs" && (value < 88 || value > 308)) {
        return true;
      }
      return false;
    },
  },
];

export const actwhen = [
  {
    key: "nihss",
    text: "NIHSS immediately (<10 minutes) prior to randomization:",
    type: "int",
    value_name: "iv07_nihss",
    length: 2,
    crf_id: "a81a1d31-53f6-4efa-9627-efb2d9d2cb84",
    crf_field_id: 2,
  },
  {
    key: "evt",
    text: "Is Emergency EVT planned at the enrolling hospital?",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    value_name: "iv07_evt",
    crf_id: "a81a1d31-53f6-4efa-9627-efb2d9d2cb84",
    crf_field_id: 3,
  },
  {
    key: "doac",
    text: "Did patient take a DOAC within the last 24 hours (≤24 hours)?",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    value_name: "iv07_doac",
    crf_id: "a81a1d31-53f6-4efa-9627-efb2d9d2cb84",
    crf_field_id: 4,
  },
];

export const bp = [
  {
    key: "nihss",
    text: "NIHSS immediately (<10 minutes) prior to randomization:",
    type: "int",
    value_name: "br09_nihss",
    length: 2,
    crf_id: "aa45e45e-d016-420d-9162-ce4f06feab87",
    crf_field_id: 2,
  },
  {
    key: "etici",
    text: "eTICI score after EVT:",
    type: "radio",
    value_name: "br09_etici",
    options: [
      { value: "≤2b", label: "≤2b" },
      { value: "≥2c", label: "≥2c" },
    ],
    crf_id: "aa45e45e-d016-420d-9162-ce4f06feab87",
    crf_field_id: 3,
  },
  {
    key: "bp",
    text: "Last Blood Pressure Prior to Randomization:",
    type: "blood_pressure",
    value_name: "br09_systBP",
    value2_name: "br09_diastBP",
    crf_id: "aa45e45e-d016-420d-9162-ce4f06feab87",
    crf_field_id: 4,
  },
];

export const act42 = [
  {
    key: "nihss",
    text: "NIHSS immediately (<10 minutes) prior to randomization:",
    type: "int",
    value_name: "ar08_nihss",
    length: 2,
    crf_id: "75018522-0e8d-4300-b524-af635ce9b7c3",
    crf_field_id: 2,
    item_specific_error_validator: (value) => {
      value = parseInt(value);
      if (value <= 5 || value > 42) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    key: "lvo",
    text: "Presence of anterior circulation large vessel occlusion",
    type: "radio",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
    value_name: "ar08_vessel",
    crf_id: "75018522-0e8d-4300-b524-af635ce9b7c3",
    crf_field_id: 3,
  },
];

export const question_array = {
  act42,
  actwhen,
  bp,
};
