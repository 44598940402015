import axios from "axios";
import { api_url } from "config";
import {
  getToken,
  setToken,
  clearToken,
  getRefreshToken,
  getUserId,
  getUserEmail,
} from "util/auth";
import store from "../redux/store";
import { setAuthState } from "../redux/auth/actions";

axios.defaults.baseURL = api_url;

const defaultHeaders = (headers = {}, userHeaders = {}) => ({
  ...headers,
  ...userHeaders,
  Authorization: "Bearer " + getToken(),
});

const handleError = async (response) => {
  if (response.ok) {
    return response;
  }
  if (response.status >= 400 && response.status <= 403) {
    let test = await response.json();

    clearToken();
    window.location.assign("/login");
  } else if (response.status === 409) {
    return response;
  } else {
    throw Error(response.statusText);
  }
};

export const get = (url, extra = {}, body = null) => {
  let config = {
    url,
    method: "GET",
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  if (extra.params) {
    config.params = extra.params;
  }
  return new Promise((resolve, reject) => {
    axios
      .request(config)
      .then((r) => resolve(r))
      .catch((r) => {
        if (r.response.status === 401) {
          axios
            .post("/auth/refresh_access_token", {
              refresh_token: getRefreshToken(),
            })
            .then((r) => {
              if (r.data && r.data.access_token) {
                setToken(r.data.access_token);
                config.headers.Authorization = `Bearer ${r.data.access_token}`;
              }
            })
            .then(() => {
              axios.request(config).then((r) => resolve(r));
            })
            .catch((r) => {
              localStorage.removeItem("token");
              store.dispatch(setAuthState(false));
              setTimeout(() => {
                if (window.location.pathname !== "/") {
                  window.location.assign("/");
                }
              }, 100);
            });
        } else if (r.response.status === 403) {
          localStorage.removeItem("token");
          store.dispatch(setAuthState(false));
          setTimeout(() => {
            if (window.location.pathname !== "/") {
              window.location.assign("/");
            }
          }, 100);
        } else {
          reject(r);
        }
      });
  });
};

export const _delete = (url, headers = {}) => {
  const options = {
    method: "DELETE",
    headers: defaultHeaders(headers),
  };
  return fetch(url, options).then(handleError);
};

export const post = (url, body, headers = {}) => {
  let config = {
    url,
    method: "POST",
    data: body,
    headers: Object.assign(
      { ...headers },
      { Authorization: `Bearer ${getToken()}` },
    ),
  };
  return new Promise((resolve, reject) => {
    axios
      .request(config)
      .then((r) => resolve(r))
      .catch((r) => {
        if (r.response.status === 401) {
          axios
            .post("/auth/refresh_access_token", {
              refresh_token: getRefreshToken(),
            })
            .then((r) => {
              if (r.data && r.data.access_token) {
                setToken(r.data.access_token);
                config.headers.Authorization = `Bearer ${r.data.access_token}`;
              }
            })
            .then(() => {
              axios.request(config).then((r) => resolve(r));
            })
            .catch(() => {
              localStorage.removeItem("token");
              store.dispatch(setAuthState(false));
              setTimeout(() => {
                if (window.location.pathname !== "/") {
                  window.location.assign("/");
                }
              }, 100);
            });
        } else if (r.response.status === 403) {
          store.dispatch(setAuthState(false));
          localStorage.removeItem("token");
          setTimeout(() => {
            if (window.location.pathname !== "/") {
              window.location.assign("/");
            }
          }, 100);
        } else {
          reject(r);
        }
      });
  });
  // if (body.constructor === Object) body = JSON.stringify(body);
  // const options = {
  //   method: "POST",
  //   headers: defaultHeaders(
  //     {
  //       "Content-Type": "application/json",
  //     },
  //     headers
  //   ),
  //   body,
  // };
  // return fetch(url, options)
  //   .then(handleError)
  //   .then((res) => res.json())
  //   .catch((err) => console.log(err));
};

export const put = (url, body, headers = {}) => {
  let config = {
    url,
    method: "PUT",
    data: body,
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  return new Promise((resolve, reject) => {
    axios
      .request(config)
      .then((r) => resolve(r))
      .catch((r) => {
        if (r.response.status === 401) {
          axios
            .post("/auth/refresh_access_token", {
              refresh_token: getRefreshToken(),
            })
            .then((r) => {
              if (r.data && r.data.access_token) {
                setToken(r.data.access_token);
                config.headers.Authorization = `Bearer ${r.data.access_token}`;
              }
            })
            .then(() => {
              axios.request(config).then((r) => resolve(r));
            })
            .catch(() => {
              localStorage.removeItem("token");
              store.dispatch(setAuthState(false));
              setTimeout(() => {
                if (window.location.pathname !== "/") {
                  window.location.assign("/");
                }
              }, 100);
            });
        }
      });
  });
  // if (body.constructor === Object) body = JSON.stringify(body);
  // const options = {
  //   method: "POST",
  //   headers: defaultHeaders(
  //     {
  //       "Content-Type": "application/json",
  //     },
  //     headers
  //   ),
  //   body,
  // };
  // return fetch(url, options)
  //   .then(handleError)
  //   .then((res) => res.json())
  //   .catch((err) => console.log(err));
};

export const post_raw = (url, body, headers = {}) => {
  return axios.post(url, body);
};

export const patch = (url, body, headers = {}) => {
  if (body.constructor === Object) body = JSON.stringify(body);
  const options = {
    method: "PATCH",
    headers: defaultHeaders(
      {
        "Content-Type": "application/json",
      },
      headers,
    ),
    body,
  };
  return fetch(url, options)
    .then(handleError)
    .then((res) => res.json())
    .catch((err) => {
      throw Error(err);
    });
};
