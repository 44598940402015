import { useEffect } from "react";
import { post } from "util/requests";
import styles from "./subject_query_view.module.css";
import QueryTable from "views/queries/components/query_table";
import { useLocation } from "react-router-dom";

export default function SubjectQueryView(props) {
  const { subject_id } = props;
  const location = useLocation();

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "queries_table",
      subject_id,
    });
  }, [location.pathname]);

  return (
    <div className={styles.wrapper}>
      <QueryTable subject_id={subject_id} />
    </div>
  );
}
