import { useState, Fragment } from "react";
import styles from "./audit_components.module.css";
import router from "routes";

const Hover = (props) => {
  const { hoverMenuRef, setFilters, row, type } = props;
  if (type === "crf_id") {
    return (
      <div ref={hoverMenuRef} className={styles.hover_menu}>
        <div
          className={styles.clickable}
          onClick={() => setFilters({ crf_id: row.crf_id })}
        >
          Filter by CRF ID
        </div>
        {row.path ? (
          <div
            className={styles.clickable}
            onClick={() => router.navigate(row.path)}
          >
            Go to CRF Page
          </div>
        ) : null}
      </div>
    );
  } else if (type === "crf_field_id") {
    return (
      <div ref={hoverMenuRef} className={styles.hover_menu}>
        <div
          className={styles.clickable}
          onClick={() => setFilters({ crf_field_id: row.crf_field_id })}
        >
          Filter by CRF Field ID
        </div>
        <div className={styles.clickable}>Go to CRF Page</div>
      </div>
    );
  } else if (type === "subject_id") {
    return (
      <div ref={hoverMenuRef} className={styles.hover_menu}>
        <div
          className={styles.clickable}
          onClick={() => setFilters({ subject_id: row.subject_id })}
        >
          Filter by Subject ID
        </div>
        <div className={styles.clickable}>Go to Subject Visit Map</div>
      </div>
    );
  } else if (type === "action") {
    return (
      <div ref={hoverMenuRef} className={styles.hover_menu}>
        <div
          className={styles.clickable}
          onClick={() => setFilters({ action: row.action })}
        >
          Filter by Action Type
        </div>
      </div>
    );
  }
};

export default function RenderFilterable(props) {
  const {
    type,
    row,
    hoverMenuRef,
    hoverMenuState,
    setHoverMenuState,
    setFilters,
  } = props;
  const [open, setOpen] = useState(false);

  const get_data = (type) => row[type];

  return (
    <Fragment>
      <div
        className={styles.clickable}
        onClick={() => {
          console.log({ row_id: row.id, type });
          setHoverMenuState({ row_id: row.id, type });
        }}
      >
        {get_data(type)}
      </div>
      {hoverMenuState &&
      hoverMenuState.row_id === row.id &&
      hoverMenuState.type === type ? (
        <Hover
          hoverMenuRef={hoverMenuRef}
          type={type}
          setFilters={setFilters}
          row={row}
        />
      ) : null}
    </Fragment>
  );
}
