import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./user_detail.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get, post } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserRoleModal from "../user_role_modal";
import UserDomainModal from "../user_domain_modal";
import UserSiteModal from "../user_site_modal";

import { useLocation } from "react-router-dom";
import { Checkbox, TextField } from "@mui/material";

const Form = (props) => {
  const { setEdit, user, siteList, study_id, getUser } = props;
  const [reinviteStatus, setReinviteStatus] = useState(null);
  const role_string =
    (user && user.role && user.role.map((r) => r.name).join(", ")) || "";
  const domain_string =
    (user && user.domains && user.domains.map((r) => r.name).join(", ")) || "";
  const sites_string =
    (user &&
      user.sites &&
      siteList
        .filter((s) => user.sites.map((s) => s.id).includes(s.id))
        .map((s) => s.name)
        .join(", ")) ||
    "";

  const resend_invite_email = () => {
    setReinviteStatus("sending");
    return new Promise((resolve) => {
      post("/resend_invite", {
        email: user.email,
        user_id: user.id,
        study_id,
      }).then(() => setReinviteStatus("complete"));
    });
  };
  return (
    <div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Email:</div>
        <div>{user.email}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>First Name:</div>
        <div>{user.first_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Last Name:</div>
        <div>{user.last_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Timezone:</div>
        <div>{user.time_zone}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Phone:</div>
        <div>{user.phone}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Roles:</div>
        <div>{role_string}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Domains:</div>
        <div>{domain_string}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Site Access:</div>
        <div>{sites_string}</div>
      </div>
      <div className={styles.profile_row}>
        <LoadingButton
          variant="contained"
          loading={false}
          onClick={() => {
            setEdit(true);
          }}
        >
          EDIT
        </LoadingButton>
      </div>

      {user.status === "invited" && reinviteStatus !== "complete" ? (
        <div className={styles.profile_row}>
          <LoadingButton
            variant="contained"
            loading={reinviteStatus === "sending"}
            onClick={() => {
              resend_invite_email();
            }}
          >
            Resend Invite Email
          </LoadingButton>
        </div>
      ) : null}
      {reinviteStatus === "complete"
        ? "This user has been resent the invite email."
        : null}
      {!user.activated_on || user.deactivated_on ? (
        <div className={styles.activation_button}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              post("/activate_user", { user_id: user.id, study_id }).then(() =>
                getUser(user.id),
              );
            }}
          >
            ACTIVATE USER
          </LoadingButton>
        </div>
      ) : null}
      {user.activated_on && !user.deactivated_on ? (
        <div className={styles.activation_button}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              post("/deactivate_user", { user_id: user.id, study_id }).then(
                () => getUser(user.id),
              );
            }}
          >
            DEACTIVATE USER
          </LoadingButton>
        </div>
      ) : null}
    </div>
  );
};

const EditForm = (props) => {
  const { user, setEdit, setUser, siteList, roleList, domainList } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [localUser, setLocalUser] = useState({
    role: [],
    first_name: "",
    last_name: "",
    time_zone: null,
    phone: "",
    email: "",
    sites: [],
  });
  const [roleSelectIsOpen, setRoleSelectIsOpen] = useState(false);
  const [domainSelectIsOpen, setDomainSelectIsOpen] = useState(false);
  const [siteSelectIsOpen, setSiteSelectIsOpen] = useState(false);

  useEffect(() => {
    setLocalUser({ ...user });
  }, []);

  const setValue = (key, value) => {
    setLocalUser({ ...localUser, [key]: value });
  };

  const updateUserRoleIds = (role_id_list) => {
    let updated_user = { ...user };
    updated_user.role = roleList.filter((r) => role_id_list.includes(r.id));
    setUser(updated_user);
    setLocalUser(updated_user);
  };

  const updateUserDomainIds = (domain_id_list) => {
    let updated_user = { ...user };
    updated_user.domains = domainList.filter((d) =>
      domain_id_list.includes(d.id),
    );
    setUser(updated_user);
    setLocalUser(updated_user);
  };

  const updateUserSiteIds = (site_id_list) => {
    let updated_user = { ...user };
    updated_user.sites = siteList.filter((s) => site_id_list.includes(s.id));
    setUser(updated_user);
    setLocalUser(updated_user);
  };

  const role_string =
    (user && user.role && user.role.map((r) => r.name).join(", ")) || "";

  const domain_string =
    (user && user.domains && user.domains.map((r) => r.name).join(", ")) || "";

  const sites_string =
    (user &&
      user.sites &&
      siteList
        .filter((s) => user.sites.map((s) => s.id).includes(s.id))
        .map((s) => s.name)
        .join(", ")) ||
    "";

  // console.log(moment.tz.names().filter((t) => t.includes("Mountain")));

  return (
    <React.Fragment>
      <div className={styles.edit_form_wrapper}>
        <div className={styles.column}>
          <div className={styles.profile_row}>
            <TextField
              disabled
              label="Email"
              placeholder="text"
              value={localUser.email}
              onChange={(e) => setValue("email", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <TextField
              label="First Name"
              placeholder="text"
              value={localUser.first_name}
              onChange={(e) => setValue("first_name", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <TextField
              label="Last Name"
              placeholder="text"
              value={localUser.last_name}
              onChange={(e) => setValue("last_name", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <Autocomplete
              value={localUser.time_zone}
              options={moment.tz.names()}
              size="small"
              sx={{
                "& .MuiAutocomplete-root": { fontSize: "10px" },
                fontSize: 12,
                height: 30,
                padding: 0,
                alignSelf: "center",
                width: 300,
              }}
              renderInput={(params) => (
                <TextField {...params} label="Timezone" />
              )}
              onChange={(e, value) => {
                setValue("time_zone", value);
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <TextField
              label="Phone"
              placeholder="text"
              value={localUser.phone}
              onChange={(e) => setValue("phone", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
        </div>

        <div className={styles.column}>
          <div>
            <div className={styles.profile_row_title}>Sites</div>
            <div className={styles.role_string_display}>{sites_string}</div>
          </div>

          <div className={styles.modify_roles_button}>
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() => {
                setSiteSelectIsOpen(true);
              }}
            >
              MODIFY SITES
            </LoadingButton>
          </div>
          <hr />
          <div>
            <div className={styles.profile_row_title}>Roles</div>
            <div className={styles.role_string_display}>{role_string}</div>
          </div>

          <div className={styles.modify_roles_button}>
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() => {
                setRoleSelectIsOpen(true);
              }}
            >
              MODIFY ROLES
            </LoadingButton>
          </div>
          <hr />

          <div>
            <div className={styles.profile_row_title}>Domains</div>
            <div className={styles.role_string_display}>{domain_string}</div>
          </div>

          <div className={styles.modify_roles_button}>
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() => {
                setDomainSelectIsOpen(true);
              }}
            >
              MODIFY DOMAIN ACCESS
            </LoadingButton>
          </div>
        </div>
      </div>
      <div className={styles.edit_form_wrapper}>
        <div className={styles.save_button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={isSaving}
            onClick={() => {
              setUser(localUser);
              // setEdit(false);
              setIsSaving(true);
              put(`/user_details/${localUser.id}`, { ...localUser }).then(
                () => {
                  setIsSaving(false);
                  setEdit(false);
                },
              );
            }}
            disabled={
              localUser.sites.length === 0 || localUser.role.length === 0
            }
          >
            SAVE
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="inherit"
            sx={{ color: "#555" }}
            loading={false}
            onClick={() => {
              setEdit(false);
            }}
            style={{ marginLeft: 20 }}
          >
            CANCEL
          </LoadingButton>
        </div>

        <UserRoleModal
          user={user}
          activeRoles={user.role}
          roleList={roleList}
          roleSelectIsOpen={roleSelectIsOpen}
          setRoleSelectIsOpen={setRoleSelectIsOpen}
          updateParent={updateUserRoleIds}
        />
        <UserDomainModal
          user={user}
          activeDomains={user.domains || []}
          domainList={domainList}
          roleSelectIsOpen={domainSelectIsOpen}
          setRoleSelectIsOpen={setDomainSelectIsOpen}
          updateParent={updateUserDomainIds}
        />
        <UserSiteModal
          user={user}
          activeSites={user.sites || []}
          siteList={siteList}
          siteSelectIsOpen={siteSelectIsOpen}
          setSiteSelectIsOpen={setSiteSelectIsOpen}
          updateParent={updateUserSiteIds}
        />
      </div>
    </React.Fragment>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className={styles.tab_panel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function UserDetails() {
  const study_id = useSelector((state) => state.study.active_study_id);
  const [isEdit, setEdit] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [siteList, setSiteList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const location = useLocation();
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
    time_zone: "",
    phone: "",
    role: [],
    domains: [],
    sites: [],
  });

  useEffect(() => {
    get(`/invite_user_sites_and_roles/${study_id}`).then((ret) => {
      const { data } = ret;
      setSiteList(data.sites);
      setRoleList(data.roles.map((r) => ({ name: r.name, id: r.id })));
      setDomainList(data.domains);
    });
  }, []);

  const getUser = (id) => {
    get(`/user/${id}/${study_id}`).then((ret) => setUser(ret.data));
  };

  useEffect(() => {
    const id =
      location && location.pathname && location.pathname.split("/").slice(-1);
    if (id) {
      getUser(id);
    }
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading1}>
        {user.first_name} {user.last_name}
      </div>

      <div className={styles.created}>
        Created:{" "}
        {`${moment(user.created_on).format("YYYY-MM-DD HH:mm:ss")} 
          ${moment.tz(moment.tz.guess()).zoneAbbr()}`}
      </div>

      {!user.activated_on && !user.deactivated_on ? (
        <div className={styles.activated}>Not Yet Activated</div>
      ) : null}
      {user.activated_on && !user.deactivated_on ? (
        <div className={styles.activated}>
          Activated:{" "}
          {`${moment(user.activated_on).format("YYYY-MM-DD HH:mm:ss")} 
          ${moment.tz(moment.tz.guess()).zoneAbbr()}`}
        </div>
      ) : null}
      {user.activated_on && user.deactivated_on ? (
        <div className={styles.activated}>
          Deactivated:{" "}
          {`${moment(user.deactivated_on).format("YYYY-MM-DD HH:mm:ss")} ${moment.tz(moment.tz.guess()).zoneAbbr()}`}
        </div>
      ) : null}

      {isEdit ? (
        <EditForm
          user={user}
          setEdit={setEdit}
          setUser={setUser}
          siteList={siteList}
          roleList={roleList}
          domainList={domainList}
          study_id={study_id}
        />
      ) : (
        <Form
          user={user}
          setEdit={setEdit}
          siteList={siteList}
          study_id={study_id}
          getUser={getUser}
        />
      )}
    </div>
  );
}
