import React, { useState } from "react";
import styles from "./user_role_modal.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, Checkbox } from "@mui/material";

export default function UserRoleDialog(props) {
  const {
    activeRoles,
    roleList,
    roleSelectIsOpen,
    setRoleSelectIsOpen,
    updateParent,
    user,
  } = props;
  const [roles, setRoles] = useState(
    (activeRoles && activeRoles.map((r) => r.id)) || [],
  );

  const updateRoles = (role_id, checked) => {
    let updated_roles = [...roles];
    if (checked) {
      updated_roles.push(role_id);
    } else {
      updated_roles = updated_roles.filter((id) => id !== role_id);
    }
    setRoles(updated_roles);
  };

  return (
    <Dialog open={roleSelectIsOpen} onClose={() => setRoleSelectIsOpen(false)}>
      <div className={styles.dialog_wrapper}>
        <div className={styles.roles_dialog_title}>
          <strong>
            Roles: {user.first_name} {user.last_name}
          </strong>
        </div>
        <div className={styles.role_wrapper}>
          {roleList.map((role) => (
            <div className={styles.role_row}>
              <Checkbox
                key={role.id}
                checked={roles.includes(role.id)}
                className={styles.checkbox}
                onChange={(e) => {
                  updateRoles(role.id, e.target.checked);
                }}
              />
              <div>{role.name}</div>
            </div>
          ))}
        </div>
        <div className={styles.dialog_button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              updateParent(roles);
              setRoleSelectIsOpen(false);
            }}
          >
            UPDATE
          </LoadingButton>
          <LoadingButton
            variant="standard"
            loading={false}
            onClick={() => {
              setRoleSelectIsOpen(false);
            }}
          >
            CANCEL
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
