import RenderDataType from "./render_data_type";
import RenderPageLoad from "./render_page_load";

export default function RenderHandler(props) {
  let { action, data } = props;
  let json;
  try {
    json = JSON.parse(data);
    if (action === "data_change") {
      return <RenderDataType json={json} />;
    } else if (action === "pageload") {
      return <RenderPageLoad json={json} />;
    }

    //   json.field_type === "date") {
    //   return <div>Date: {json.payload.value}</div>;
    // } else if (json.field_type === "time") {
    //   return <div>Time: {json.payload.value}</div>;
    // } else if (json.field_type === "radio") {
    //   return <div>Data: {json.payload.value}</div>;
    // } else if (json.field_type === "select") {
    //   return <div>Data: {json.payload.value}</div>;
    // }
  } catch (err) {
    console.log(err);
  }

  return <div>{data}</div>;
}
