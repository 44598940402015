import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { get, put, post } from "util/requests";
import styles from "./visit_map_builder.module.css";
import crf_styles from "../edc/components/crf/crf.module.css";
import { TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import { cloneDeep } from "lodash";

function Options(props) {
  const { field, setMainassemblyOptions } = props;
  const [options, setOptions] = useState(
    (field.options && JSON.stringify(field.options)) || "",
  );

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      setMainassemblyOptions(field.id, JSON.parse(options));
    }
  };

  const onBlur = (key) => {
    setMainassemblyOptions(field.id, JSON.parse(options));
  };

  if (["radio", "select"].includes(field.type)) {
    return (
      <div>
        <TextField
          placeholder="text"
          value={options}
          onChange={(e) => {
            setOptions(e.target.value);
          }}
          onKeyDown={onKeyDown}
          inputProps={{
            onBlur: onBlur,
          }}
          onBlur={onBlur}
          type="area"
          autoComplete="off"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              textAlign: "center",
              width: "400px",
            },
          }}
        />
      </div>
    );
  } else return null;
}

function EditableText(props) {
  const { text, onChange, width = 600 } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [localText, setLocalText] = useState(text);

  // if (isEdit)
  return (
    <div className={styles.editable_text}>
      <div
        className={styles.internal_editable_text_field}
        contentEditable={isEdit}
        onChange={(e) => console.log(e.target)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setLocalText(
              e.currentTarget.innerHTML
                .replace(/<[^>]+>/g, "")
                .replace(/<[^>]+>/g, "")
                .replace("&nbsp;", ""),
            );
            setIsEdit(false);
            onChange(e.currentTarget.innerHTML);
          }
        }}
        onBlur={(e) => {
          setLocalText(
            e.currentTarget.innerHTML
              .replace(/<[^>]+>/g, "")
              .replace(/<[^>]+>/g, "")
              .replace("&nbsp;", ""),
          );
          setIsEdit(false);
          onChange(
            e.currentTarget.innerHTML
              .replace(/<[^>]+>/g, "")
              .replace(/<[^>]+>/g, "")
              .replace("&nbsp;", ""),
          );
        }}
        dangerouslySetInnerHTML={{ __html: localText || "placeholder" }}
        onClick={() => {
          setIsEdit(true);
        }}
      ></div>
    </div>
  );
  // else
  //   return (
  //     <div
  //       onClick={() => {
  //         setIsEdit(true);
  //       }}
  //     >
  //       {localText}{" "}
  //     </div>
  //   );
}

function Selections(props) {
  const { field, setMainassemblyOptions } = props;
  const [options, setOptions] = useState(field.options || []);

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      setMainassemblyOptions(field.id, options);
    }
  };

  const modifyOptionLabel = (index, value) => {
    let opt = cloneDeep(options);
    opt[index].label = value;
    setOptions(opt);
    setMainassemblyOptions(field.id, opt);
  };

  const modifyOptionValue = (index, value) => {
    let opt = cloneDeep(options);
    opt[index].value = value;
    setOptions(opt);
    setMainassemblyOptions(field.id, opt);
  };

  const addOptionField = () => {
    let opt = cloneDeep(options);
    opt.push({ label: "label", value: "value" });
    setOptions(opt);
  };

  const deleteOption = (index) => {
    let opt = cloneDeep(options);
    opt.splice(index, 1);
    setOptions(opt);
    setMainassemblyOptions(field.id, opt);
  };

  const optionsYesNo = () => {
    let opt = [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
    setOptions(opt);
    setMainassemblyOptions(field.id, opt);
  };

  if (["radio", "select"].includes(field.type)) {
    return (
      <div>
        <div className={styles.options_row_wrapper}>
          {options.map((option, index) => {
            return (
              <div className={styles.options_row} key={index}>
                Label:
                <EditableText
                  text={option.label}
                  onChange={(value) => modifyOptionLabel(index, value)}
                />
                Value:
                <EditableText
                  text={option.value}
                  onChange={(value) => modifyOptionValue(index, value)}
                />
                <div onClick={() => deleteOption(index)}>delete</div>
                {/*<TextField
                placeholder="text"
                value={option.value}
                onChange={(e) => {
                  setOptions(e.target.value);
                }}
                onKeyDown={onKeyDown}
                type="area"
                autoComplete="off"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "200px",
                  },
                }}
              />*/}
              </div>
            );
          })}
        </div>
        <div onClick={addOptionField} className={styles.add_button}>
          Add
        </div>
        {field.type === "radio" && (
          <div onClick={optionsYesNo} className={styles.add_button}>
            Yes/No
          </div>
        )}
      </div>
    );
  } else return null;
}

export default function AssemblyBuilder() {
  const [assembly, setAssembly] = useState(null);
  const [activeCrf, setActiveCrf] = useState(null);
  useEffect(() => {
    get(`/crf_assembly`)
      .then((ret) => {
        if (
          (ret.data && Object.keys(ret.data).length > 0) ||
          (ret.data && ret.data.name)
        ) {
          setAssembly(ret.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const addField = () => {
    let _assembly = { ...assembly };
    if (
      !assembly.design.fields ||
      Object.keys(assembly.design.fields).length === 0
    ) {
      _assembly.design = { fields: [{ id: 1, label: "new" }] };
    } else {
      _assembly.design.fields.push({
        id: assembly.design.fields.slice(-1)[0].id + 1,
        label: "new",
      });
    }
    setAssembly(_assembly);
  };
  const options = [
    { label: "Date", value: "date" },
    { label: "Date Time", value: "datetime" },
    { label: "Radio", value: "radio" },
    { label: "Select", value: "select" },
    { label: "Int", value: "int" },
    { label: "Year", value: "year" },
    { label: "Decimal", value: "decimal" },
    { label: "Blood Pressure", value: "blood_pressure" },
    { label: "Heading", value: "heading" },
  ];

  const changeName = (name) => {
    let _assembly = { ...assembly };
    _assembly.name = name;
    setAssembly(_assembly);
  };
  const changeLabel = (id, label) => {
    let _assembly = { ...assembly };
    let field = _assembly.design.fields.find((f) => f.id === id);
    field.label = label;
    setAssembly(_assembly);
  };
  const changeType = (id, type) => {
    let _assembly = { ...assembly };
    let field = _assembly.design.fields.find((f) => f.id === id);
    field.type = type;
    setAssembly(_assembly);
  };

  const setOptions = (id, options) => {
    let _assembly = { ...assembly };
    console.log("options are", options);
    let field = _assembly.design.fields.find((f) => f.id === id);
    field.options = options;
    setAssembly(_assembly);
  };

  const copyOverActiveCrf = (dest_crf) => {
    if (activeCrf) {
      post("/copy_crf", { source_crf: activeCrf, dest_crf });
    }
  };

  if (!assembly) return null;
  console.log(assembly);
  return (
    <div className={crf_styles.wrapper}>
      <div className={styles.entry_row}>
        <strong>Modify Visit Map</strong>
        {activeCrf && (
          <div style={{ marginLeft: 20 }}>
            {activeCrf.name} - {activeCrf.day_number} - {activeCrf.crf_id}
          </div>
        )}
      </div>
      <br />
      <div>
        {Object.values(assembly.design.crfs).map((crf) => (
          <div className={styles.entry_row}>
            <div>{crf.name}</div>
            {Object.entries(crf.visits).map(([key, value]) => {
              if (value.constructor === Object) {
                return (
                  <div className={styles.crf_tile}>
                    {key} {value.id.slice(0, 8)}
                    <div className={styles.entry_row}>
                      <div
                        className={styles.button}
                        onClick={() =>
                          setActiveCrf({
                            name: crf.name,
                            day_number: key,
                            crf_id: value.id,
                          })
                        }
                      >
                        copy
                      </div>
                      <div
                        className={styles.button}
                        onClick={() =>
                          copyOverActiveCrf({
                            name: crf.name,
                            day_number: key,
                            crf_id: value.id,
                          })
                        }
                      >
                        paste
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        ))}
        <br />
        {Object.values(assembly.design.global_forms).map((crf) => (
          <div>{crf.name}</div>
        ))}
      </div>
    </div>
  );
}
