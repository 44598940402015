import React from "react";
import styles from "./segment.module.css";
import QaUI from "../../../views/edc/components/qa_ui";

import { post } from "util/requests";

export default function Segment(props) {
  const {
    title,
    crf_id,
    crf_data,
    subject_id,
    updateCrfStatus,
    updateCrfSignStatus,
    crf_status,
    crf_sign_status,
  } = props;
  return (
    <div className={styles.segment}>
      <div className={styles.top_wrapper}>
        <div className={styles.top_split_left}>
          <strong>{title}</strong>{" "}
        </div>
        <div className={styles.top_split_right}>
          <QaUI
            crf_id={crf_id}
            form_name={crf_data && crf_data.name && crf_data.name.toUpperCase()}
            crf_data={crf_data}
            crf_status={crf_status}
            crf_sign_status={crf_sign_status}
            subject_id={subject_id}
            updateCrfStatus={updateCrfStatus}
            updateCrfSignStatus={updateCrfSignStatus}
          />
        </div>
      </div>
      <hr />
      <div className={styles.children}>{props.children}</div>
    </div>
  );
}
