import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import RadioGroupUI from "ui/crfs/radio_group/components/radio_group_ui";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./elgibility.module.css";
import router from "routes";
import { useParams, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { get, post } from "util/requests";
import moment from "moment";
import ConfirmConsentModal from "./components/confirm_consent_modal";

const act42 = {
  title: "ACT-42",
  inclusion: [
    {
      text: "Age ≥ 18 years on the date of randomization?",
      key: "1",
      crf_id: "7fe0c3c2-f9d8-415e-a03d-d1297c232883",
      crf_field_id: 2,
      value_name: "pe02_randAge",
    },
    {
      text: "Clinical diagnosis of stroke:",
      key: "2",
      crf_id: "7fe0c3c2-f9d8-415e-a03d-d1297c232883",
      crf_field_id: 3,
      value_name: "pe02_diag",
    },
    {
      text: "Acute ischemic stroke selected for intravenous thrombolysis with or without EVT.",
      key: "3",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 2,
      value_name: "ad05_ic01",
    },
    {
      text: "Onset (last-known-well) time to randomization time within 3 hours.",
      key: "4",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 3,
      value_name: "ad05_ic02",
    },
    {
      text: "Ages ≥45 to ≤90 years.",
      key: "5",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 4,
      value_name: "ad05_ic03",
    },
    {
      text: "Disabling stroke defined as a baseline National Institutes of Health Stroke Score > 5.",
      key: "6",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 5,
      value_name: "ad05_ic04",
    },
    {
      text: "Confirmed symptomatic anterior circulation intracranial occlusion. Tandem extracranial carotid and intracranial occlusions are permitted.",
      key: "7",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 6,
      value_name: "ad05_ic05",
    },
    {
      text: "Pre-stroke independent functional status in activities of daily living as judged by the enrolling physician. Patient must be living without requiring nursing care.",
      key: "8",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 7,
      value_name: "ad05_ic06",
    },
    {
      text: "Consent process completed as per national laws and regulation and the applicable ethics committee requirements.",
      key: "9",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 8,
      value_name: "ad05_ic07",
    },
  ],
  exclusion: [
    {
      text: "Large extent early ischemic changes/infarct in the ischemic territory on qualifying imaging, defined as early ischemic changes on NCCT.",
      key: "1",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 10,
      value_name: "ad05_ec01",
    },
    {
      text: "Any intracranial hemorrhage on qualifying imaging.",
      key: "2",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 11,
      value_name: "ad05_ec01",
    },
    {
      text: "Unlikely to initiate study drug/placebo administration before arterial puncture in those selected for EVT.",
      key: "3",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 12,
      value_name: "ad05_ec01",
    },
    {
      text: "Estimated or known weight > 115 kg (253 lbs).",
      key: "4",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 13,
      value_name: "ad05_ec01",
    },
    {
      text: "Known/suspected pregnancy and/or lactation.",
      key: "5",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 14,
      value_name: "ad05_ec01",
    },
    {
      text: "Systolic blood pressure < 90 mmHg",
      key: "6",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 15,
      value_name: "ad05_ec01",
    },
    {
      text: "Known prior receipt of ACT-42 for any reason, including prior enrolment in this trial.",
      key: "7",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 16,
      value_name: "ad05_ec01",
    },
    {
      text: "Severe comorbid illness with life expectancy less than 90 days, or likely to prevent completing 90-day follow-up.",
      key: "8",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 17,
      value_name: "ad05_ec01",
    },
    {
      text: "Long term care facility resident or prisoner.",
      key: "9",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 18,
      value_name: "ad05_ec01",
    },
    {
      text: "Participation in another clinical trial investigating a drug or medical device or a neuro-interventional or surgical procedure that is not considered as standard care in the 30 days preceding trial enrolment.",
      key: "10",
      crf_id: "17335480-ecb1-4d8e-a44d-eb5f2c5465d1",
      crf_field_id: 19,
      value_name: "ad05_ec01",
    },
  ],
};

const actwhen = {
  title: "Intravenous Thrombolysis",
  inclusion: [
    {
      text: "Age ≥ 18 years on the date of randomization?",
      key: "1",
    },
    {
      text: "Clinical diagnosis of stroke:",
      key: "2",
    },
    {
      text: "Is the patient presenting with a suspected acute ischemic stroke (including stroke on awakening or within 9 hours of stroke onset)?",
      key: "3",
    },
    {
      text: "OIs there active haemorrhage in the brain or elsewhere in the body?",
      key: "4",
    },
    {
      text: "Is the patient eligible for acute treatment with standard dose intravenous tenecteplase?",
      key: "5",
    },
  ],
  exclusion: [],
};

const bp = {
  title: "Blood Pressure",
  inclusion: [
    {
      text: "Age ≥ 18 years on the date of randomization?",
      key: "1",
    },
    {
      text: "Clinical diagnosis of stroke:",
      key: "2",
    },
    {
      text: "Treatment with endovascular therapy (EVT) < 24 hours after symptom onset/last known well",
      key: "3",
    },
    {
      text: "Sustained systolic BP ≥150 mmHg (defined as 2 successive readings <10 mins apart) within 3 hours after EVT",
      key: "4",
    },
  ],
  exclusion: [
    {
      text: "Any definite contraindications to BP lowering treatment?",
      key: "1",
    },
  ],
};

export default function Eligibility(props) {
  const { domain_id } = useParams();
  const user_data = useSelector((state) => state.user.userData);
  const study_id = useSelector((state) => state.study.active_study_id);

  const [data, setData] = useState({ inclusion: {}, exclusion: {} });
  const [questionArray, setQuestionArray] = useState([]);
  const [deferred, setDeferred] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [existingSubject, setExistingSubject] = useState(null);
  const [invalidSubject, setInvalidSubject] = useState(null);
  const [noSubjectAccess, setNoSubjectAccess] = useState(null);
  const [windowPassed, setWindowPassed] = useState(null);
  const [showConfirmEnrollmentModal, setShowConfirmEnrollmentModal] =
    useState(false);
  const [noAccess, setNoAccess] = useState(false);

  const location = useLocation();
  const subject_id_ref = useRef(null);

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "eligibility",
    });
  }, [location.pathname]);

  useEffect(() => {
    get(`/eligibility_questions/${domain_id}`).then((ret) => {
      if (ret.data && ret.data.status && ret.data.status === "no_access") {
        setNoAccess(true);
      } else {
        setQuestionArray(ret.data);
      }
    });
  }, []);

  const updateValues = (inclusion_or_exclusion, key, value_obj) => {
    // console.log("value_obj", value_obj);
    const _data = cloneDeep(data);
    if (inclusion_or_exclusion === "inclusion") {
      _data.inclusion[key] = value_obj[key];
    } else if (inclusion_or_exclusion === "exclusion") {
      _data.exclusion[key] = value_obj[key];
    }
    setData(_data);
  };

  let question_array = questionArray;
  // if (domain_id === "act42") question_array = act42;
  // if (domain_id === "actwhen") question_array = actwhen;
  // if (domain_id === "bp") question_array = bp;

  const setAll = (inclusion_or_exclusion) => {
    const _data = cloneDeep(data);
    if (inclusion_or_exclusion === "inclusion") {
      question_array.inclusion.forEach(
        (item) => (_data.inclusion[item.key] = "yes"),
      );
    } else if (inclusion_or_exclusion === "exclusion") {
      question_array.exclusion.forEach(
        (item) => (_data.exclusion[item.key] = "no"),
      );
    }
    setData(_data);
  };

  const setDataValue = (key, value) => {
    const obj = cloneDeep(data);
    obj[key] = value;
    setData(obj);
  };

  const handleNextClick = () => {
    if (data.deferred === "yes") {
      if (study_id === 2 && !showConfirmEnrollmentModal) {
        setShowConfirmEnrollmentModal(true);
      } else {
        let now = new Date();
        setSpinning(true);
        //here we need to create the subject id for deferred then spit it out to randomize
        post("/consent_record", {
          consent_type: "deferred_waiver_consent",
          site_code: user_data.site_code,
          domain_code: domain_id,
          existing_subject_id: data.existing_subject_id,
          question_array,
          date: moment(now).format("YYYY-MM-DD"),
          time: moment(now).format("HH:mm"),
          study_id,
        }).then((ret) => {
          let { id } = ret.data;
          console.log("back from consent record", study_id);
          if (study_id === 2) {
            setShowConfirmEnrollmentModal(false);
            router.navigate(`/enrollment_complete/${id}`);
          } else {
            router.navigate(`/randomize_subject/${id}`);
          }
        });
      }
    } else {
      if (data.existing_subject_id) {
        router.navigate(
          `/consent_subject/${domain_id}/${data.existing_subject_id}`,
        );
      } else {
        router.navigate(`/consent_subject/${domain_id}`);
      }
    }
  };

  const block_next_button = () => {
    // console.log(data);
    if (!data.deferred) {
      return true;
    } else if (
      data.existing_subject_id &&
      data.existing_subject_id.length < 5
    ) {
      return true;
    } else if (
      question_array.inclusion.reduce((accum, item) => {
        if (data.inclusion[item.key] !== "yes" || accum) {
          accum = true;
        }
        return accum;
      }, false)
    ) {
      // console.log("returning inclusion", question_array);
      return true;
    } else if (
      question_array.exclusion.reduce((accum, item) => {
        if (data.exclusion[item.key] !== "no" || accum) {
          accum = true;
        }
        return accum;
      }, false)
    ) {
      // console.log("returning inclusion", question_array);
      return true;
    } else {
      return false;
    }
  };

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      subject_id_ref.current.blur();
    }
  };

  const handleConfirmConsentSubmitAction = () => {
    handleNextClick();
  };

  if (noAccess) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.not_available}>
          You do not have access to enroll patients to this domain.
        </div>
        <div className={styles.not_available}>
          Your site may not be enabled for enrollment, or your user may not have
          the appropriate permissions.
        </div>
        <div className={styles.not_available}>
          If you believe this to be an error, please contact us at
          actglobal@ucalgary.ca.
        </div>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.title}>
          {question_array.title} Domain Eligibility Form
        </div>
        {domain_id !== "ecore" ? (
          <div className={styles.subject_entry_row}>
            <div className={styles.left}>
              <div>Enrolled in another domain?</div>
              <div> If so, enter their Subject ID.</div>
            </div>

            <div className={styles.subject_entry_wrapper}>
              <TextField
                inputRef={subject_id_ref}
                placeholder=""
                value={data.existing_subject_id}
                inputProps={{ maxLength: 5 }}
                autoComplete="off"
                onChange={(e) => {
                  setDataValue("existing_subject_id", e.target.value);
                  setInvalidSubject(false);
                  setExistingSubject(null);
                  setNoSubjectAccess(null);
                  setWindowPassed(null);
                }}
                onKeyDown={onKeyDown}
                onBlur={() => {
                  setInvalidSubject(false);

                  if (data.existing_subject_id.length >= 5) {
                    post("/check_existing_subject_id", {
                      existing_subject_id: data.existing_subject_id,
                      domain_code: domain_id,
                    }).then(({ data }) => {
                      if (data.invalid_subject) {
                        //show message if the subject does not exit
                        setInvalidSubject(true);
                        return;
                      } else if (data.subject_consented) {
                        //subject data obj will come in with consented and randomized details
                        setExistingSubject(data);
                      } else if (data.no_access) {
                        setNoSubjectAccess(true);
                      } else if (data.window_has_passed) {
                        setWindowPassed(true);
                      } else {
                        setExistingSubject(false);
                      }
                    });
                  } else {
                    setExistingSubject(null);
                  }
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    paddingLeft: "10px",
                    textAlign: "left",
                    width: "80px",
                    fontSize: 14,
                  },
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.subject_entry_row}></div>
        )}
        {!existingSubject &&
        !invalidSubject &&
        !noSubjectAccess &&
        !windowPassed &&
        question_array.inclusion &&
        question_array.inclusion.length ? (
          <div>
            <div className={styles.sub_title}>
              <strong>INCLUSION CRITERIA</strong>
            </div>
            <div className={styles.all_button}>
              <LoadingButton
                variant="contained"
                loading={false}
                onClick={() => setAll("inclusion")}
                sx={{
                  fontSize: 14,
                  padding: "2px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                Yes to All
              </LoadingButton>
            </div>
            {question_array.inclusion.map((item) => (
              <div className={styles.eligibility_row}>
                <div className={styles.left}>{item.text}</div>
                <div>
                  <RadioGroupUI
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value_position={item.key}
                    field={{ value_obj: data.inclusion }}
                    onUpdate={(value_obj) =>
                      updateValues("inclusion", item.key, value_obj)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {!existingSubject &&
      !noSubjectAccess &&
      !invalidSubject &&
      !windowPassed &&
      question_array.exclusion &&
      question_array.exclusion.length ? (
        <div>
          <div className={styles.sub_title}>
            <strong>EXCLUSION CRITERIA</strong>
          </div>
          <div className={styles.all_button}>
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() => setAll("exclusion")}
              sx={{
                fontSize: 14,
                padding: "2px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              No to All
            </LoadingButton>
          </div>
          {question_array.exclusion.map((item) => (
            <div className={styles.eligibility_row}>
              <div className={styles.left}>{item.text}</div>
              <div>
                <RadioGroupUI
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                  value_position={item.key}
                  field={{ value_obj: data.exclusion }}
                  onUpdate={(value_obj) =>
                    updateValues("exclusion", item.key, value_obj)
                  }
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {!existingSubject &&
      !invalidSubject &&
      !noSubjectAccess &&
      !windowPassed ? (
        <div>
          <div className={styles.sub_title}>
            <strong>CONSENT</strong>
          </div>
          <div className={styles.eligibility_row}>
            <div className={styles.left}> Is consent deferred?</div>
            <div>
              <RadioGroupUI
                options={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ]}
                value_position={"deferred"}
                field={{ value_obj: data }}
                onUpdate={(value_obj) => setData(cloneDeep(value_obj))}
              />
            </div>
          </div>
        </div>
      ) : null}
      {!existingSubject &&
      !invalidSubject &&
      !noSubjectAccess &&
      !windowPassed ? (
        <div className={styles.next_button}>
          <LoadingButton
            variant="contained"
            loading={spinning}
            onClick={handleNextClick}
            disabled={block_next_button()}
          >
            Next
          </LoadingButton>
        </div>
      ) : null}

      {existingSubject && existingSubject.subject_randomized ? (
        <div className={styles.existing_feedback_wrapper}>
          <div className={styles.already_exists}>
            Subject {data.existing_subject_id} already exists for this domain.
          </div>
          <LoadingButton
            variant="contained"
            loading={spinning}
            onClick={() =>
              router.navigate(
                `/study/${study_id}/site/1/subjects/${data.existing_subject_id}/view`,
              )
            }
          >
            Go To Visit Map
          </LoadingButton>
        </div>
      ) : null}

      {noSubjectAccess ? (
        <div className={styles.existing_feedback_wrapper}>
          <div className={styles.already_exists}>
            You do not have access to subject {data.existing_subject_id}.
          </div>
          <LoadingButton
            variant="contained"
            loading={spinning}
            onClick={() => {
              setNoSubjectAccess(null);
              setDataValue("existing_subject_id", "");
            }}
          >
            Clear Entry
          </LoadingButton>
        </div>
      ) : null}

      {windowPassed ? (
        <div className={styles.existing_feedback_wrapper}>
          <div className={styles.already_exists}>
            Too much time has passed. You can no longer enroll subject{" "}
            {data.existing_subject_id} into another domain.
          </div>
          <LoadingButton
            variant="contained"
            loading={spinning}
            onClick={() => {
              setWindowPassed(null);
              setDataValue("existing_subject_id", "");
            }}
          >
            Clear Entry
          </LoadingButton>
        </div>
      ) : null}

      {existingSubject &&
      existingSubject.subject_consented &&
      !existingSubject.subject_randomized ? (
        <div className={styles.existing_feedback_wrapper}>
          <div className={styles.already_exists}>
            Subject {data.existing_subject_id} exists, but is not randomized for
            this domain.
          </div>
          <LoadingButton
            variant="contained"
            loading={spinning}
            onClick={() =>
              router.navigate(
                `/randomize_subject/${existingSubject.consent_id}`,
              )
            }
          >
            Randomize Now
          </LoadingButton>
        </div>
      ) : null}

      {invalidSubject ? (
        <div className={styles.existing_feedback_wrapper}>
          <div className={styles.already_exists}>
            Subject {data.existing_subject_id} does not exist.
          </div>
          <LoadingButton
            variant="contained"
            loading={spinning}
            onClick={() => {
              setInvalidSubject(false);
              setDataValue("existing_subject_id", "");
            }}
          >
            Clear Entry
          </LoadingButton>
        </div>
      ) : null}
      <ConfirmConsentModal
        showing={showConfirmEnrollmentModal}
        closeModal={() => {}}
        handleSubmitAction={handleConfirmConsentSubmitAction}
      />
    </div>
  );
}
