import { useState } from "react";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import styles from "./blood_test_result.module.css";
import RadioGroupUI from "ui/crfs/radio_group/components/radio_group_ui";
import IntUI from "ui/crfs/int/components/int_ui";
import DecimalUI from "ui/crfs/decimal/components/decimal_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import { cloneDeep } from "lodash";

let tempStorage = null;
export default function BloodTestResult(props) {
  const {
    field,
    title,
    options,
    instruction,
    instruction_color,
    sub_title,
    onUpdate,
    enableQuery,
    setQuery,
    query,
    crf_id,
    field_id,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    crfStatus,
    read_only,
  } = props;

  const [localVal, setLocalVal] = useState(field.value_obj || {});
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  const acceptTempStorage = () => {
    onUpdate(localVal);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };
  const closeModal = () => {
    setLocalVal(tempStorage);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };

  const updateValue = (position, value_obj) => {
    let _obj = cloneDeep(localVal);
    if (crfStatus === "data_complete") {
      tempStorage = cloneDeep(localVal);
      _obj[position] = value_obj[position];
      setLocalVal(_obj);
      setShowingCompleteChangeModal(true);
      return;
    }
    _obj[position] = value_obj[position];
    _obj.value_name = field.value_name;
    _obj.value2_name = field.value2_name;
    _obj.value3_name = field.value3_name;
    _obj.value4_name = field.value4_name;
    setLocalVal(_obj);
    onUpdate(_obj);
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          <NotificationWidget {...props} />
        </div>
        <div className={[styles["right-split"], styles.date_entry].join(" ")}>
          {field.options && field.options.is_available ? (
            <div className={styles.inner_row}>
              <div className={styles.inner_row_title}>Result available:</div>
              {read_only ? (
                <div>
                  {["yes", "no"].includes(field.value_obj.value) &&
                  field.value_obj.value
                    ? "Yes"
                    : "No"}
                </div>
              ) : (
                <RadioGroupUI
                  {...props}
                  onUpdate={(value_obj) => updateValue("value", value_obj)}
                  field={{ ...field, value_obj: localVal }}
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "No" },
                  ]}
                  value_position={"value"}
                  value_name_position={"value_name"}
                />
              )}
            </div>
          ) : null}
          {field.options && field.options.int_value ? (
            <div className={styles.inner_row}>
              <div className={styles.inner_row_title}>Value:</div>
              {read_only ? (
                <div>
                  {field.value_obj.value2} {field.options.int_value.units}
                </div>
              ) : (
                <IntUI
                  {...props}
                  onUpdate={(value_obj) => updateValue("value2", value_obj)}
                  field={{ ...field, value_obj: localVal }}
                  value_position={"value2"}
                  value_name_position={"value2_name"}
                  units={field.options.int_value.units || ""}
                />
              )}
            </div>
          ) : null}
          {field.options && field.options.decimal_value ? (
            <div className={styles.inner_row}>
              <div className={styles.inner_row_title}>Value:</div>
              {read_only ? (
                <div>
                  {field.value_obj.value3 || "---"}{" "}
                  {field.options.decimal_value.units}
                </div>
              ) : (
                <DecimalUI
                  {...props}
                  field={{ ...field, value_obj: localVal }}
                  onUpdate={(value_obj) => updateValue("value3", value_obj)}
                  value_position={"value3"}
                  value_name_position={"value3_name"}
                  units={field.options.decimal_value.units || ""}
                  whole_length={field.options.decimal_value.whole_length}
                  decimal_length={field.options.decimal_value.decimal_length}
                />
              )}
            </div>
          ) : null}
          {field.options && field.options.units_value ? (
            <div className={styles.inner_row}>
              <div className={styles.inner_row_title}>Unit:</div>
              {read_only ? (
                <div>{field.value_obj.value4 || "---"}</div>
              ) : (
                <RadioGroupUI
                  {...props}
                  field={{ ...field, value_obj: localVal }}
                  onUpdate={(value_obj) => updateValue("value4", value_obj)}
                  options={field.options.units_value}
                  value_position={"value4"}
                  value_name_position={"value4_name"}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>

      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
