import { useEffect, useState, Fragment } from "react";
import { get, put, post } from "util/requests";
import { useSelector } from "react-redux";
import styles from "./role_config.module.css";
import { TextField, Checkbox } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CheckIcon from "@mui/icons-material/Check";
import { cloneDeep } from "lodash";

const EditableRow = (props) => {
  const { id, selected, onSelect, text, onEdit, acceptEdit } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [localText, setLocalText] = useState(text);
  return (
    <div
      className={selected ? styles.selected_row : styles.row}
      onClick={onSelect}
      // onBlur={() => setIsEdit(false)}
    >
      <div>
        {isEdit ? (
          <TextField
            autoFocus
            value={localText}
            onChange={(e) => setLocalText(e.target.value)}
            onBlur={(e) => e.preventDefault()}
          />
        ) : (
          <div>{localText}</div>
        )}
      </div>
      <div>
        {isEdit ? (
          <CheckIcon
            className={styles.table_action_icon}
            sx={{ fontSize: 18, color: "#555" }}
            onClick={() => {
              acceptEdit(localText);
              setIsEdit(false);
            }}
          />
        ) : (
          <Fragment>
            <ModeEditIcon
              className={styles.table_action_icon}
              sx={{ fontSize: 18, color: "#555" }}
              onClick={() => setIsEdit(true)}
            />
            <DeleteIcon
              className={styles.table_action_icon}
              onClick={() => {}}
              sx={{ fontSize: 18, color: "#e66767" }}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default function RoleConfig(props) {
  const [data, setData] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);
  const [isAddPermission, setIsAddPermission] = useState(false);
  const [isAddRole, setIsAddRole] = useState(false);
  const [newPermissionText, setNewPermissionText] = useState("");
  const [newRoleText, setNewRoleText] = useState("");

  const active_study_id =
    useSelector((state) => state.study.active_study_id) || 1;

  useEffect(() => {
    get(`/role_data/${active_study_id}`).then(({ data }) => setData(data));
  }, []);

  const updateRoleName = (id, name) => {
    put(`/role/${id}`, { name, study_id: active_study_id });
  };

  const updateRolePermissionMap = (role_id, permission_id, is_add) => {
    let permission_ids =
      (data.map &&
        data.map
          .filter((m) => m.role_id === role_id)
          .map((m) => m.permission_id)) ||
      [];
    if (is_add) {
      permission_ids.push(permission_id);
    } else {
      permission_ids = checked_permissions.filter((id) => id !== permission_id);
    }
    post(`/role_permissions_ids/${role_id}`, {
      study_id: active_study_id,
      permission_ids,
    }).then(({ data }) => setData(data));
  };

  const updateRoleCRFMap = (role_id, crf_slug, is_add, permission) => {
    let crf_slugs =
      (data.crf_permission &&
        data.crf_permission.filter((m) => m.role_id === role_id)) ||
      [];
    if (is_add) {
      if (
        permission === "write" &&
        crf_slugs.find((crf) => crf.crf_slug === crf_slug)
      ) {
        //remove from list so that we can overwrite read with write permission
        crf_slugs = crf_slugs.filter((crf) => crf.crf_slug !== crf_slug);
      }
      crf_slugs.push({ crf_slug: crf_slug, permission });
    } else {
      crf_slugs = crf_slugs.filter((crf) => crf.crf_slug !== crf_slug);
    }
    post(`/role_crf_permissions/${role_id}`, {
      crf_slugs,
      study_id: active_study_id,
    }).then(({ data }) => setData(data));
  };

  const addPermission = () => {
    post("/permission/", {
      name: newPermissionText,
      study_id: active_study_id,
    }).then(({ data }) => {
      setData(data);
      setIsAddPermission(false);
    });
  };

  const addRole = () => {
    post("/role/", { name: newRoleText, study_id: active_study_id }).then(
      ({ data }) => {
        setData(data);
        setIsAddRole(false);
      },
    );
  };

  let checked_permissions =
    (data.map &&
      data.map
        .filter((m) => m.role_id === selectedRole)
        .map((m) => m.permission_id)) ||
    [];

  let checked_crfs =
    (data.crf_permission &&
      data.crf_permission.filter((crfp) => crfp.role_id === selectedRole)) ||
    [];
  // console.log("checked_crfs", checked_crfs);
  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        {data.roles &&
          data.roles.map((role) => (
            <EditableRow
              id={role.id}
              text={role.name}
              selected={role.id === selectedRole}
              onSelect={() => setSelectedRole(role.id)}
              acceptEdit={(value) => updateRoleName(role.id, value)}
            />
          ))}
        {isAddRole ? (
          <div className={styles.row}>
            <TextField
              value={newRoleText}
              onChange={(e) => setNewRoleText(e.target.value)}
            />{" "}
            <CheckIcon
              className={styles.table_action_icon}
              sx={{ fontSize: 18, color: "#555" }}
              onClick={addRole}
            />
          </div>
        ) : (
          <AddCircleOutlineIcon
            className={styles.table_action_icon}
            onClick={() => setIsAddRole(true)}
          />
        )}
      </div>
      <div className={styles.column}>
        {data.permissions &&
          data.permissions.map((permission) => (
            <div>
              <Checkbox
                key={permission.id}
                checked={checked_permissions.includes(permission.id)}
                className={styles.checkbox}
                onChange={(e) => {
                  if (selectedRole)
                    updateRolePermissionMap(
                      selectedRole,
                      permission.id,
                      e.target.checked,
                    );
                }}
              />
              {permission.name}
            </div>
          ))}
        {!isAddPermission ? (
          <AddCircleOutlineIcon
            className={styles.table_action_icon}
            onClick={() => setIsAddPermission(true)}
          />
        ) : null}
        {isAddPermission ? (
          <div className={styles.row}>
            <TextField
              value={newPermissionText}
              onChange={(e) => setNewPermissionText(e.target.value)}
            />{" "}
            <CheckIcon
              className={styles.table_action_icon}
              sx={{ fontSize: 18, color: "#555" }}
              onClick={addPermission}
            />
          </div>
        ) : null}
      </div>
      <div>
        <div>Read Write</div>
        <div className={styles.column}>
          {data.crf_assembly &&
            data.crf_assembly.crfs.map((crf) => (
              <div>
                <Checkbox
                  key={`${crf.slug}-read`}
                  checked={checked_crfs
                    .filter((p) => ["read", "write"].includes(p.permission))
                    .map((p) => p.crf_slug)
                    .includes(crf.slug)}
                  className={styles.checkbox}
                  onChange={(e) => {
                    if (selectedRole)
                      updateRoleCRFMap(
                        selectedRole,
                        crf.slug,
                        e.target.checked,
                        "read",
                      );
                  }}
                  disabled={checked_crfs
                    .filter((p) => p.permission === "write")
                    .map((p) => p.crf_slug)
                    .includes(crf.slug)}
                />
                <Checkbox
                  key={`${crf.slug}-write`}
                  checked={checked_crfs
                    .filter((p) => p.permission === "write")
                    .map((p) => p.crf_slug)
                    .includes(crf.slug)}
                  onChange={(e) => {
                    if (selectedRole)
                      updateRoleCRFMap(
                        selectedRole,
                        crf.slug,
                        e.target.checked,
                        "write",
                      );
                  }}
                />
                {crf.name}
              </div>
            ))}
          {data.crf_assembly &&
            data.crf_assembly.global_forms.map((crf) => (
              <div>
                <Checkbox
                  key={`${crf.slug}-read`}
                  checked={checked_crfs
                    .filter((p) => ["read", "write"].includes(p.permission))
                    .map((p) => p.crf_slug)
                    .includes(crf.slug)}
                  className={styles.checkbox}
                  onChange={(e) => {
                    if (selectedRole)
                      updateRoleCRFMap(
                        selectedRole,
                        crf.slug,
                        e.target.checked,
                        "read",
                      );
                  }}
                  disabled={checked_crfs
                    .filter((p) => p.permission === "write")
                    .map((p) => p.crf_slug)
                    .includes(crf.slug)}
                />
                <Checkbox
                  key={`${crf.slug}-write`}
                  checked={checked_crfs
                    .filter((p) => p.permission === "write")
                    .map((p) => p.crf_slug)
                    .includes(crf.slug)}
                  className={styles.checkbox}
                  onChange={(e) => {
                    if (selectedRole)
                      updateRoleCRFMap(
                        selectedRole,
                        crf.slug,
                        e.target.checked,
                        "write",
                      );
                  }}
                />
                {crf.name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
