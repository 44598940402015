import React, { useRef, useEffect, useState } from "react";
import { get } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./drug_management.module.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DrugTable from "./components/drug_table";

export default function Audit() {
  const [auditLogs, setAuditLogs] = useState([]);
  const [drugs, setDrugs] = useState([]);

  return (
    <div className={styles.flex}>
      <div className={styles.header}>
        <div>
          <h3>Drug Management</h3>
        </div>
        <div className={styles.search_wrapper}></div>
      </div>
      <DrugTable />
    </div>
  );
}
