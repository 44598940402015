import React from "react";
import SmsOutlined from "@mui/icons-material/Sms";
import Check from "@mui/icons-material/Check";
import Description from "@mui/icons-material/Description";

import ModeCommentOutlined from "@mui/icons-material/ModeCommentOutlined";
// import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import styles from "./icons.module.css";

const NotifyIcon = React.forwardRef((props, ref) => (
  <ModeCommentOutlined
    {...props}
    ref={ref}
    className={styles.notify_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const ResolvedIcon = React.forwardRef((props, ref) => (
  <ModeCommentOutlined
    {...props}
    ref={ref}
    className={styles.resolved_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const RespondedIcon = React.forwardRef((props, ref) => (
  <ModeCommentOutlined
    {...props}
    ref={ref}
    className={styles.responded_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const CheckIcon = React.forwardRef((props, ref) => (
  <Check
    {...props}
    ref={ref}
    className={styles.check_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const DataIcon = React.forwardRef((props, ref) => (
  <Description
    {...props}
    ref={ref}
    className={styles.data_icon}
    sx={{ fontSize: props.fontSize }}
  />
));

export { NotifyIcon, ResolvedIcon, RespondedIcon, CheckIcon, DataIcon };
