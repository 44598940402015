const initState = { authState: "loading" };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case "set_auth_state":
      return { ...state, authState: action.payload };
    default:
      return state;
  }
}
