import style from "./heading.module.css";
import DividerLine from "ui/crfs/divider_line";

export default function Heading(props) {
  const { color, position } = props;

  let _style = [style.heading];
  if (color === "red") {
    _style.push(style.red);
  }
  if (position === "center") {
    _style.push(style.center);
  }
  _style = _style.join(" ");
  if (props.title) {
    return (
      <div>
        <div className={_style}>
          <strong>{props.title}</strong>
        </div>
        <DividerLine />
      </div>
    );
  } else {
    return null;
  }
}
