import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { post } from "util/requests";
import ctms_logo from "assets/act_global_logo.png";
import router from "routes";

export default function PasswordResetForm(props) {
  const { onComplete = () => {} } = props;
  const [isSent, setIsSent] = useState(false);
  const [is_loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const password_valid = () =>
    password &&
    password2 &&
    password === password2 &&
    /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-#$\.%&*!\(\)_|])(?=.*[a-zA-Z]).{8,20}/.test(
      password.trim(),
    )
      ? true
      : false;

  const handleReset = () => {
    if (is_loading) return;
    setIsLoading(true);
    post("/auth/reset_password", { password }).then(() => {
      setIsLoading(false);
      setIsSent(true);
      onComplete();
    });
  };

  const handleFieldChange = (key) => (e) => {
    if (key === "password") setPassword(e.target.value);
    else if (key === "password2") setPassword2(e.target.value);
    setErrorMessage(null);
  };

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      handleReset();
    }
  };
  return (
    <React.Fragment>
      <h2 style={{ color: "#333" }}>Enter Your New Password</h2>
      <div style={{ textAlign: "left" }}>
        <div style={{ marginBottom: 4 }}>Passwords must:</div>
        <div style={{ paddingLeft: 10 }}>
          <div style={{ marginBottom: 4 }}>• be 8-20 characters in length</div>
          <div style={{ marginBottom: 4 }}>
            • contain a lower case and upper case character
          </div>
          <div style={{ marginBottom: 4 }}>• contain a number</div>
          <div style={{ marginBottom: 4 }}>
            • contain a special character like:-#$.%&*!_()|
          </div>
        </div>
      </div>
      <div className="margin-b-1">
        <TextField
          label="New Password"
          variant="standard"
          onChange={handleFieldChange("password")}
          onKeyDown={onKeyDown}
          type="password"
        />
      </div>
      <div className="margin-b-1">
        <TextField
          label="New Password Again"
          variant="standard"
          onChange={handleFieldChange("password2")}
          onKeyDown={onKeyDown}
          type="password"
        />
      </div>

      <LoadingButton
        variant="contained"
        loading={is_loading}
        onClick={handleReset}
        disabled={!password_valid()}
      >
        RESET PASSWORD
      </LoadingButton>
      {errorMessage ? <div>{errorMessage}</div> : null}
    </React.Fragment>
  );
}
