import { createContext, useState, useContext } from "react";
import router from "./routes";
import { logout } from "util/auth";

export const FooContext = createContext();

const { Provider } = FooContext;

const minutes = 15;

let timer = null;
const clearTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(logout, minutes * 60 * 1000);
};

const startTimer = () => {
    timer = setTimeout(logout, minutes * 60 * 1000);
};

export const TimerProvider = ({ children }) => {
    return <Provider value={{ clearTimer, startTimer }}>{children}</Provider>;
};

export const useStartTimer = () => {
    const { startTimer } = useContext(FooContext);
    return startTimer;
};

export const useResetTimer = () => {
    // console.log("timer reset");
    const { clearTimer } = useContext(FooContext);
    return clearTimer;
};
