import React, { useRef, useEffect, useState } from "react";
import styles from "./decimal.module.css";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import DecimalUI from "./components/decimal_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";

export default function Decimal(props) {
  const { title, enableQuery, field, read_only, crfStatus, onUpdate } = props;
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);
  const [localVal, setLocalVal] = useState(field.value_obj || {});
  const acceptTempStorage = () => {
    onUpdate(localVal);
    setShowingCompleteChangeModal(false);
  };
  const closeModal = () => {
    setShowingCompleteChangeModal(false);
    setLocalVal({ ...field.value_obj });
  };

  const updateValue = (value_obj) => {
    if (crfStatus === "data_complete") {
      setLocalVal({ ...value_obj });
      setShowingCompleteChangeModal(true);
      return;
    }
    setLocalVal(value_obj);
    onUpdate(value_obj);
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget
                {...props}
                currentValueDisplay={
                  (field.value_obj.value &&
                    `${field.value_obj.value} ${field.units || ""}`) ||
                  "---"
                }
              />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>

        <div className={styles["right-split"]}>
          {read_only ? (
            <div>{field.value_obj.value || "---"}</div>
          ) : (
            <DecimalUI
              {...props}
              field={{ ...field, value_obj: localVal }}
              onUpdate={updateValue}
              units={field.units || ""}
            />
          )}
        </div>
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
