import React, { useRef } from "react";
import styles from "./electronic_consent_form.module.css";
import { TextField } from "@mui/material";
import { Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Signature from "./components/signature";

export default function ConsentContent(props) {
  const { onComplete } = props;

  return (
    <div className={styles.content_wrapper}>
      <div className={styles.title}>
        (IN PROGRESS) Participant Information Sheet and Consent Form
      </div>
      <div className={styles.block}>
        <div>
          <strong>Title:</strong> A multi-faCtorial, mulTi-arm, multi-staGe,
          randomised, gLOBal Adaptive pLatform trial for stroke{" "}
        </div>
        <div>
          <strong>Short Title:</strong> ACT-GLOBAL
        </div>
        <div>
          <strong>Protocol Number:</strong> xxxxx
        </div>
        <div>
          <strong>Project Sponsor:</strong> The George Institute for Global
          Health{" "}
        </div>
        <div>
          <strong>Coordinating Principal Investigator:</strong> Professor Craig
          Anderson
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.title}>
          Part 1 - What does my participation involve?
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.title}>Declaration by Participant</div>
      </div>

      <div className={styles.checkbox_row}>
        <Checkbox
          key={"1"}
          checked={false}
          className={styles.checkbox}
          onChange={(e) => console.log(e.target.checked)}
        />
        I have read and understood the Participant Information Sheet regarding
        the ACT-GLOBAL research study and have discussed the study with.
      </div>

      <div>
        I hearby agree to participate in following parts of the research study
      </div>
      <div>Blood Pressure Domain</div>

      <div className={styles.small_block}>
        <div className={styles.text_field_title}>Name of Participant</div>
        <TextField
          onChange={(e) => console.log(e.target.value)}
          autoComplete="off"
          label=""
          sx={{ width: "400px" }}
        />
      </div>

      <div className={styles.small_block}>
        <div className={styles.text_field_title}>Address of Participant</div>
        <TextField
          onChange={(e) => console.log(e.target.value)}
          autoComplete="off"
          sx={{ width: "400px" }}
        />
      </div>

      <div className={styles.small_block}>
        <Signature label="Signature of Participant" />
      </div>

      <div className={styles.small_block}>
        <div className={styles.text_field_title}>
          Name of Person conducting informed consent
        </div>
        <TextField
          onChange={(e) => console.log(e.target.value)}
          autoComplete="off"
          sx={{ width: "400px" }}
        />
      </div>

      <div className={styles.small_block}>
        <Signature label="Signature of Person Conducting Informed Consent" />
      </div>

      <div className={styles.small_block}>
        <div className={styles.text_field_title}>Name of Witness</div>
        <TextField
          onChange={(e) => console.log(e.target.value)}
          autoComplete="off"
          sx={{ width: "400px" }}
        />
      </div>

      <div className={styles.small_block}>
        <Signature label="Signature of Witness" />
      </div>

      <div style={{ marginBottom: 20 }}>
        <LoadingButton variant="contained" loading={false} onClick={onComplete}>
          SUBMIT
        </LoadingButton>
      </div>
    </div>
  );
}
