import React, { useRef, useEffect, useState } from "react";
import { get } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./ae.module.css";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AETable from "./components/ae_table";

const columns = [
  { field: "email", headerName: "User", width: 200 },
  { field: "action", headerName: "Action", width: 260 },
  {
    field: "created_on",
    headerName: "Date",
    width: 160,
    renderCell: ({ row }) => {
      return moment(row.created_on).format("yyyy-MMM-DD HH:mm:ss");
    },
  },
  {
    field: "data",
    headerName: "Meta Data",
    width: 800,
  },
];

export default function Audit() {
  const [auditLogs, setAuditLogs] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userFilter, setUserFilter] = useState(null);
  const searchLogs = () => {
    get("/audit/logs", { params: { user: userFilter } }).then((ret) =>
      setAuditLogs(ret.data),
    );
  };
  useEffect(() => {
    searchLogs();
    get("/audit/user_list").then((ret) => setUserList(ret.data));
  }, []);

  useEffect(() => {
    searchLogs();
  }, [userFilter]);

  return (
    <div className={styles.flex}>
      <div className={styles.header}>
        <div>
          <h3>Adverse Events / Serious Adverse Events</h3>
        </div>
        <div className={styles.search_wrapper}></div>
      </div>
      <AETable />
    </div>
  );
}
