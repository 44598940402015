import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../user_profile.module.css";
import { Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { get, post } from "util/requests";
import { TextField } from "@mui/material";

export default function NotificationPreference() {
  const permissions = useSelector((state) => state.user.userData.permissions);
  const [notificationPreferences, setNotificationPreferences] = useState(null);

  useEffect(() => {
    get("/user_notifications").then((ret) => {
      setNotificationPreferences(ret.data);
    });
  }, []);

  // const hanndleGoogleChecked = (value) => {
  //   setGoogleChecked(value);
  //   if (value === false) {
  //     setValue("google_2fa", false);
  //   }
  // };

  // const handleGoogleAuthCodeEntry = () => {
  //   if (!googleCode || (googleCode && googleCode.length !== 6)) {
  //     setGoogleError("Code is the wrong length.");
  //     return;
  //   }

  //   setCheckingAuthCode(true);
  //   post("/validate_active_google2fa_enable_code", {
  //     qr_code: googleCode,
  //   })
  //     .then(() => {
  //       setValue("google_2fa", true);
  //       setCheckingAuthCode(false);
  //       setGoogleCode("");
  //     })
  //     .catch(() => {
  //       setGoogleError("Invalid code, please try again.");
  //       setCheckingAuthCode(false);
  //       setCheckingAuthCode(false);
  //       setGoogleCode("");
  //     });
  // };

  // const handleChangePassword = () => {};

  const setValue = (key, checked) => {
    let _notificationPreferences = [...notificationPreferences];
    if (!checked) {
      _notificationPreferences.push(key);
    } else {
      _notificationPreferences = _notificationPreferences.filter(
        (n) => n !== key,
      );
    }
    post("/user_notifications", { preferences: _notificationPreferences });
    setNotificationPreferences(_notificationPreferences);
  };

  // const onKeyDown2fa = (key) => {
  //   if (key.key === "Enter") {
  //     handleGoogleAuthCodeEntry();
  //   }
  // };

  return (
    <div>
      {permissions.includes("randomization_general_notification") ||
      permissions.includes("randomization_allocation_notification") ? (
        <React.Fragment>
          <div className={styles.section_title}>Randomization</div>

          <div className={styles.section_wrapper}>
            {permissions.includes("randomization_general_notification") ? (
              <div>
                <Checkbox
                  checked={
                    notificationPreferences &&
                    !notificationPreferences.includes(
                      "disable_general_randomization_notification",
                    )
                  }
                  className={""}
                  onChange={(e) =>
                    setValue(
                      "disable_general_randomization_notification",
                      e.target.checked,
                    )
                  }
                />
                Enable General Randomization Emails
              </div>
            ) : null}
            {permissions.includes("randomization_allocation_notification") ? (
              <div>
                <Checkbox
                  checked={
                    notificationPreferences &&
                    !notificationPreferences.includes(
                      "disable_randomization_allocation_notification",
                    )
                  }
                  className={""}
                  onChange={(e) =>
                    setValue(
                      "disable_randomization_allocation_notification",
                      e.target.checked,
                    )
                  }
                />
                Enable Randomization Allocation Emails (attached PDF report)
              </div>
            ) : null}
          </div>
        </React.Fragment>
      ) : null}
      {permissions.includes("sae_creation_notification") ? (
        <React.Fragment>
          <div className={styles.section_title}>Adverse Events</div>
          <div>
            <Checkbox
              checked={
                notificationPreferences &&
                !notificationPreferences.includes(
                  "disable_initial_sae_notification",
                )
              }
              className={""}
              onChange={(e) =>
                setValue("disable_initial_sae_notification", e.target.checked)
              }
            />
            Enable SAE Notifications (initial)
          </div>
          <div>
            <Checkbox
              checked={
                notificationPreferences &&
                !notificationPreferences.includes(
                  "disable_followup_sae_notification",
                )
              }
              className={""}
              onChange={(e) =>
                setValue("disable_followup_sae_notification", e.target.checked)
              }
            />
            Enable Follow-up SAE Notifications
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}
