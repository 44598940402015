import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import styles from "../radio_group.module.css";
import { cloneDeep } from "lodash";

export default function RadioGroupUI(props) {
  const {
    field,
    key,
    options,
    onUpdate,
    orientation,
    value_position = "value",
    value_name_position = "value_name",
    key_prefix,
  } = props;

  const updateValue = (newValue) => {
    let obj = cloneDeep(field.value_obj);
    if (newValue === field.value_obj[value_position]) {
      obj[value_position] = "";
    } else {
      obj[value_position] = newValue;
    }
    obj[value_name_position] =
      field[value_name_position] || field.value_obj[value_name_position] || "";

    onUpdate(obj);
  };
  return (
    <div
      className={
        orientation === "vertical"
          ? styles.radio_group_vertical
          : styles.radio_group_flex
      }
    >
      {options
        ? options.map((o) => (
            <div
              className={styles.radio_item_wrapper}
              key={`${o.value}`}
              onClick={() => {
                updateValue(o.value);
              }}
            >
              <Radio
                key={`${o.value}`}
                size="small"
                checked={o.value === field.value_obj[value_position] || false}
                sx={{
                  padding: "0px",
                  marginRight: "4px",
                }}
              />
              <div className={styles.radio_text}>{o.label}</div>
            </div>
          ))
        : null}
    </div>
  );
}
