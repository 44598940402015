import { useEffect } from "react";
import { post } from "util/requests";
import styles from "./adverse_events_view.module.css";
import { LogFormTable } from "views/edc/components/crf/log_form_table";
import { useLocation } from "react-router-dom";

export default function AdverseEventsView(props) {
  const { subject_id } = props;
  const location = useLocation();

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "ae_table",
      subject_id,
    });
  }, [location.pathname]);

  return (
    <div className={styles.wrapper}>
      <LogFormTable subject_id={subject_id} crf_id={"adverse_events"} />
    </div>
  );
}
