const initState = { userData: { permissions: [], domains: [] } };

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case "set_user_data":
      return { ...state, userData: action.payload };
    default:
      return state;
  }
}
