import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import router from "routes";
import styles from "./enrollment_complete.module.css";

export default function EnrollmentComplete(props) {
  const { subject_id } = useParams();
  return (
    <div className={styles.wrapper}>
      <div>
        <span className={styles.subject_text}>Subject ID: </span>
        <strong>
          <span className={styles.subject_id}>{subject_id}</span>
        </strong>
      </div>
      <div>Enrollment is complete.</div>
      <LoadingButton
        variant="contained"
        loading={false}
        onClick={() =>
          router.navigate(`/study/${2}/site/1/subjects/${subject_id}/view`)
        }
      >
        Go To Visit Map
      </LoadingButton>
    </div>
  );
}
