import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./sidebar.module.css";
import { get } from "util/requests";
import CheckBoxIcon from "@mui/icons-material/TaskAlt";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import SmsOutlined from "@mui/icons-material/Sms";
import router from "routes";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  CheckIcon,
  DataIcon,
  RespondedIcon,
  NotifyIcon,
  ResolvedIcon,
} from "ui/icons";

const layer1 = [styles.sidebar_layer, styles.sidebar_layer1].join(" ");
const layer2 = [styles.sidebar_layer, styles.sidebar_layer2].join(" ");
const layer2_active = [
  styles.sidebar_layer,
  styles.sidebar_layer2,
  styles.layer2_active,
].join(" ");
const layer3 = [styles.sidebar_layer, styles.sidebar_layer3].join(" ");

const renderRowIcon = ({ query_status, status }) => {
  let ret = [];

  if (query_status === "checked") {
    ret.push(
      <CheckBoxIcon
        sx={{ color: "#10944f", fontSize: "16px", lineHeight: "16px" }}
      />,
    );
  } else if (query_status === "open" || query_status === "action") {
    ret.push(<NotifyIcon fontSize="15px" />);
  } else if (query_status === "resolved" || query_status === "closed") {
    ret.push(<ResolvedIcon fontSize="15px" />);
  } else if (query_status === "responded" || query_status === "okay") {
    ret.push(<RespondedIcon fontSize="15px" />);
  }

  if (status === "data") {
    ret.push(<DataIcon fontSize={"16px"} />);
  } else if (status === "data_complete") {
    ret.push(<CheckIcon fontSize="16px" />);
  } else if (status === "available") {
    ret.push(
      <PanoramaFishEyeIcon
        sx={{ color: "#bbb", fontSize: "16px", lineHeight: "16px" }}
      />,
    );
  }
  if (!status) {
    ret.push(
      <PanoramaFishEyeIcon
        sx={{ color: "#bbb", fontSize: "16px", lineHeight: "16px" }}
      />,
    );
  }
  return ret;
};

const Row = function (props) {
  return (
    <div
      key={props.crf_id}
      {...props}
      className={props.is_active ? layer2_active : layer2}
    >
      <div className={styles.sidebar_text}>{props.name}</div>
      <div className={styles.icon_tags}>{renderRowIcon(props)}</div>
    </div>
  );
};

export default function Sidebar(props) {
  const {
    crf_id,
    visit_index,
    subject_id,
    domain_id,
    crfList,
    globalFormsList,
  } = props;

  const [visits, setVisits] = useState([]);
  const study_id = useSelector((state) => state.study.active_study_id);

  useEffect(() => {
    get(`/crf_visits_for_domain/${study_id}`).then((ret) => {
      let visits = ret.data.visits;
      setVisits(visits);
    });
  }, []);

  return (
    <div className={styles.sidebar}>
      <div>
        <div className={styles.visit_select_header}>
          <strong>Current Visit</strong>
        </div>
        <div className={styles.visit_select_wrapper}>
          <Select
            labelId="demo-select-small"
            value={visit_index}
            sx={{
              "& .Mui-focusVisible": { fontSize: "10px" },
              fontSize: 12,
              width: "90%",
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    padding: "6px",
                    fontSize: "14px",
                  },
                },
              },
            }}
          >
            {visits.map((visit) => (
              <MenuItem
                key={visit.id}
                value={visit.id}
                onClick={() => {
                  router.navigate(
                    `/study/1/site/1/subjects/${subject_id}/edc/visit/${visit.id}`,
                  );
                }}
              >
                {visit.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={layer1}>
        <div>
          <strong>Visit Pages</strong>
        </div>
      </div>

      {crfList &&
        crfList
          .filter(({ category }) => category === "main")
          .map((crf) => (
            <Row
              id={crf.id}
              name={crf.name}
              is_active={crf.id === crf_id}
              status={crf.status}
              query_status={crf.query_status}
              status={crf.status}
              onClick={() =>
                router.navigate(
                  `/study/1/site/1/subjects/${subject_id}/edc/visit/${visit_index}/crf/${crf.id}`,
                )
              }
            />
          ))}
      {crfList &&
      crfList.filter(({ category }) => category === "study_level_single_entry")
        .length ? (
        <React.Fragment>
          <div style={{ height: 14 }} />
          <div className={layer1}>
            <div>
              <strong>Study Level Single Entry Forms</strong>
            </div>
          </div>
          {crfList &&
            crfList
              .filter(({ category }) => category === "study_level_single_entry")
              .map((crf) => (
                <Row
                  id={crf.id}
                  name={crf.name}
                  is_active={crf.id === crf_id}
                  status={crf.status}
                  query_status={crf.query_status}
                  status={crf.status}
                  onClick={() =>
                    router.navigate(
                      `/study/1/site/1/subjects/${subject_id}/edc/visit/${visit_index}/crf/${crf.id}`,
                    )
                  }
                />
              ))}
        </React.Fragment>
      ) : null}
      {globalFormsList && globalFormsList.length ? (
        <React.Fragment>
          <div style={{ height: 14 }} />
          <div className={layer1}>
            <div>
              <strong>Study Level Multiple Entry Forms</strong>
            </div>
          </div>
          {globalFormsList &&
            globalFormsList.map((form) => (
              <Row
                id={form.id}
                name={form.name}
                is_active={form.id === crf_id}
                status={"status"}
                onClick={() =>
                  router.navigate(
                    `/study/1/site/1/subjects/${subject_id}/edc/visit/${visit_index}/multi_entry/${form.id}`,
                  )
                }
              />
            ))}
        </React.Fragment>
      ) : null}
      <div style={{ height: 20 }} />
    </div>
  );
}
