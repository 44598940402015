import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { post } from "util/requests";
import "../signin/index.css";
import ctms_logo from "assets/ctms_logo.png";
import act_global_logo from "assets/act_global_logo.png";
import router from "routes";
import { useParams } from "react-router-dom";

const error_message = "Sorry, your login was not correct.";
const logo = window.location.hostname.includes("actglobaltrial.com")
  ? act_global_logo
  : ctms_logo;

export default function PasswordResetForm() {
  const { reset_token } = useParams();
  const [is_loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const [isSent, setIsSent] = useState(false);
  const [isValidToken, setIsValidToken] = useState(null);

  const password_valid = () =>
    password &&
    password2 &&
    password === password2 &&
    /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-#$\.%&*!\(\)_|])(?=.*[a-zA-Z]).{8,20}/.test(
      password.trim(),
    )
      ? true
      : false;

  useEffect(() => {
    post("/check_reset_password_token", { token: reset_token })
      .then(() => {
        setIsValidToken(true);
      })
      .catch((err) => {
        setIsValidToken(false);
      });
  }, []);

  const handleReset = () => {
    if (is_loading) return;

    setIsLoading(true);
    post("/reset_password_complete", { token: reset_token, password }).then(
      () => {
        setIsLoading(false);
        setIsSent(true);
      },
    );
  };

  const handleFieldChange = (key) => (e) => {
    if (key === "password") setPassword(e.target.value);
    else if (key === "password2") setPassword2(e.target.value);
    setErrorMessage(null);
  };

  const onKeyDown = (key) => {
    if (key.key === "Enter") {
      handleReset();
    }
  };

  return (
    <div className="wrapper">
      {!isSent ? (
        <div className="login-card">
          <img src={logo} width="400px" className="logo" />
          {isValidToken ? (
            <React.Fragment>
              <h2 style={{ color: "#333" }}>Enter Your New Password</h2>
              <div style={{ textAlign: "left" }}>
                <div style={{ marginBottom: 4 }}>Passwords must:</div>
                <div style={{ paddingLeft: 10 }}>
                  <div style={{ marginBottom: 4 }}>
                    • be 8-20 characters in length
                  </div>
                  <div style={{ marginBottom: 4 }}>
                    • contain a lower case and upper case character
                  </div>
                  <div style={{ marginBottom: 4 }}>• contain a number</div>
                  <div style={{ marginBottom: 4 }}>
                    • contain a special character like:-#$.%&*!_()|
                  </div>
                </div>
              </div>
              <div className="margin-b-1">
                <TextField
                  label="New Password"
                  variant="standard"
                  onChange={handleFieldChange("password")}
                  onKeyDown={onKeyDown}
                  type="password"
                />
              </div>
              <div className="margin-b-1">
                <TextField
                  label="New Password Again"
                  variant="standard"
                  onChange={handleFieldChange("password2")}
                  onKeyDown={onKeyDown}
                  type="password"
                />
              </div>

              <LoadingButton
                variant="contained"
                loading={is_loading}
                onClick={handleReset}
                disabled={!password_valid()}
              >
                RESET PASSWORD
              </LoadingButton>
              {errorMessage ? <div>{errorMessage}</div> : null}
            </React.Fragment>
          ) : null}
        </div>
      ) : (
        <div className="login-card">
          <img src={logo} width="400px" className="logo" />

          <div style={{ marginTop: 40 }}>
            Your password has been reset. Please sign in.
          </div>
          <LoadingButton
            variant="contained"
            loading={is_loading}
            onClick={() => router.navigate("/")}
            style={{ width: 200, alignSelf: "center", marginTop: 20 }}
          >
            SIGN IN
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
