import styles from "../subjects.module.css";

const mapDomainLabel = (domain_key) => {
  const domains = {
    bp: "BP",
    act42: "ACT-42",
    actwhen: "ACTWHEN",
    ecore: "ECORE",
  };
  return domains[domain_key];
};

export default function DomainTag(props) {
  const { text } = props;
  return <div className={styles.domain_tile}>{mapDomainLabel(text)}</div>;
}
