import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get, post } from "util/requests";
import colors from "colors";
import moment from "moment";
import styles from "./deviation_view.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import DomainTag from "views/subjects/components/domain_tag";
import DangerousIcon from "@mui/icons-material/DangerousOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHighRounded";
import { useLocation } from "react-router-dom";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 20,
  },
  {
    field: "domain_code",
    headerName: "Domain",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <React.Fragment>
        {(row.domain_code && (
          <div className={styles.domain_tile_wrapper}>
            <DomainTag text={row.domain_code} />
          </div>
        )) || <div />}
      </React.Fragment>
    ),
  },
  {
    field: "created_on",
    headerName: "Date",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      (row.created_on && moment(row.created_on).format("yyyy-MMM-DD HH:mm")) ||
      "",
  },
  {
    field: "crf_name",
    headerName: "CRF",
    width: 240,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "type",
    headerName: "Type",
    width: 100,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <React.Fragment>
        {(row.type && (
          <div className={styles.icon_wrapper}>
            {row.type === "violation" ? (
              <DangerousIcon
                className={styles.violation_icon}
                sx={{ fontSize: "18px" }}
              />
            ) : (
              <PriorityHighIcon
                className={styles.violation_icon}
                sx={{ fontSize: "18px" }}
              />
            )}
          </div>
        )) || <div />}
      </React.Fragment>
    ),
  },
];

export function Table(props) {
  const {
    crf_id,
    subject_id,
    domain_id,
    updateCrfStatus,
    form_type,
    multi_entry_crf_id,
    data,
  } = props;

  return (
    <DataGrid
      rows={data}
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={(row) => {}}
      components={{
        NoRowsOverlay: () => (
          <div className={styles.no_rows_overlay}>
            No Deviations or Violations
          </div>
        ),
      }}
      onRowClick={(data) => {
        router.navigate(`${data.row.pathname}`);
      }}
    />
  );
}

export default function DeviationsView(props) {
  const { crf_id, subject_id, domain_id } = props;
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "deviations_table",
      subject_id,
    });
  }, [location.pathname]);

  useEffect(() => {
    get(`/deviations_table_view/${subject_id}`).then((ret) =>
      setData(ret.data),
    );
  }, [subject_id]);

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.stretch_header}>
          <div>
            <strong>Deviations</strong>
          </div>
        </div>
      </div>

      <Table {...props} data={data} />
    </div>
  );
}
