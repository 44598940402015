export const pad_time_value = (value) => {
  const digits = 2;
  let s = "0" + value;
  return s.substr(s.length - digits);
};

export const pad_patient_id = (patient_id) => {
  const digits = 3;
  let s = "000" + patient_id;
  return s.substr(s.length - digits);
};

export const padStringId = (string_id) => {
  if (!string_id) return "";
  const digits = 5;
  string_id = string_id.split("-");
  let s = "00000" + string_id[1];
  return `${string_id[0]}-${s.substr(s.length - digits)}`;
};

export const parseTime = (value) => {
  let ret = { hours: "", minutes: "" };
  if (value && value.constructor === String) {
    value = value.split(":");
    if (value.length === 2) {
      ret.hours = value[0];
      ret.minutes = value[1];
    }
  }
  return ret;
};

export const emailvalid = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
    ? true
    : false;
