import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get, post } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./index.module.css";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";
import RenderHander from "./components/render_handler";
import RenderFilterable from "./components/render_filterable";
import Date from "ui/general/date";

export default function Audit() {
  const [auditLogs, setAuditLogs] = useState([]);
  const [userList, setUserList] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [userFilter, setUserFilter] = useState(null);
  const [subjectIdFilter, setSubjectIdFilter] = useState("");
  const [subjectCRFIdFilter, setSubjectCRFIdFilter] = useState("");
  const [subjectCRFFieldIdFilter, setSubjectCRFFieldIdFilter] = useState("");
  const [actionFilter, setActionFilter] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [hoverMenuState, setHoverMenuState] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const location = useLocation();

  const study_id = useSelector((state) => state.study.active_study_id);

  const subjectRef = useRef(null);
  const crfRef = useRef(null);
  const crfFieldRef = useRef(null);
  const hoverMenuRef = useRef(null);

  const clearSearchFilters = () => {
    setUserFilter(null);
    setSubjectIdFilter("");
    setSubjectCRFIdFilter("");
    setSubjectCRFFieldIdFilter("");
    setActionFilter("");
    setDateFilter("");
  };

  const searchLogs = (page = 0) => {
    let user_email = (userFilter && userFilter.split("|")[1].trim()) || "";
    let subject_id = subjectIdFilter;
    let crf_id = subjectCRFIdFilter;
    let crf_field_id = subjectCRFFieldIdFilter;
    let date = dateFilter;
    let action = actionFilter;
    get(`/audit/logs`, {
      params: {
        user: user_email,
        subject_id,
        crf_id,
        crf_field_id,
        action,
        date,
        study_id,
        page: parseInt(page) || 0,
      },
    }).then((ret) => {
      setAuditLogs(ret.data);
      setPaginationModel({ pageSize: 100, page: parseInt(page) || 0 });
    });
  };
  useEffect(() => {
    searchLogs();
    get("/audit/user_list").then((ret) => setUserList(ret.data));
    get("/audit/action_list").then((ret) => setActionList(ret.data));
    document.addEventListener("mousedown", handleDocumentClick);
  }, []);

  const handleDocumentClick = (event) => {
    if (
      event.target &&
      hoverMenuRef &&
      hoverMenuRef.current &&
      !hoverMenuRef.current.contains(event.target)
    ) {
      setHoverMenuState(false);
    }
  };

  useEffect(() => {
    searchLogs();
  }, [userFilter, actionFilter, dateFilter, triggerSearch]);

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "audit_table",
    });
  }, [location.pathname]);

  const columns = [
    { field: "email", headerName: "User", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      renderCell: ({ row }) => {
        return (
          <RenderFilterable
            row={row}
            type={"action"}
            hoverMenuRef={hoverMenuRef}
            hoverMenuState={hoverMenuState}
            setHoverMenuState={setHoverMenuState}
            setFilters={setFilters}
          />
        );
      },
    },
    {
      field: "created_on",
      headerName: `Date (${moment.tz(moment.tz.guess()).zoneAbbr()})`,
      width: 160,
      renderCell: ({ row }) => {
        return moment(row.created_on).format("yyyy-MMM-DD HH:mm:ss");
      },
    },
    {
      field: "subject_id",
      headerName: "Subject ID",
      width: 90,
      renderCell: ({ row }) => {
        return (
          <RenderFilterable
            row={row}
            type={"subject_id"}
            hoverMenuRef={hoverMenuRef}
            hoverMenuState={hoverMenuState}
            setHoverMenuState={setHoverMenuState}
            setFilters={setFilters}
          />
        );
      },
    },
    {
      field: "data",
      headerName: "Data",
      width: 600,
      renderCell: ({ row }) => {
        return <RenderHander action={row.action} data={row.data} />;
      },
    },
    {
      field: "crf_id",
      headerName: "CRF ID",
      width: 300,
      renderCell: ({ row }) => {
        return (
          <RenderFilterable
            row={row}
            type={"crf_id"}
            hoverMenuRef={hoverMenuRef}
            hoverMenuState={hoverMenuState}
            setHoverMenuState={setHoverMenuState}
            setFilters={setFilters}
          />
        );
      },
    },
    {
      field: "crf_field_id",
      headerName: "Field ID",
      width: 80,
      renderCell: ({ row }) => {
        return (
          <RenderFilterable
            row={row}
            type={"crf_field_id"}
            hoverMenuRef={hoverMenuRef}
            hoverMenuState={hoverMenuState}
            setHoverMenuState={setHoverMenuState}
            setFilters={setFilters}
          />
        );
      },
    },
  ];

  const onSubjectKeyDown = (key) => {
    if (key.keyCode === 13) {
      subjectRef.current.blur();
      crfRef.current.blur();
      crfFieldRef.current.blur();
    }
  };

  const setFilters = (filters) => {
    if (filters.crf_id) {
      setSubjectCRFIdFilter(filters.crf_id);
    } else if (filters.crf_field_id) {
      setSubjectCRFFieldIdFilter(filters.crf_field_id);
    } else if (filters.subject_id) {
      setSubjectIdFilter(filters.subject_id);
    } else if (filters.action) {
      setActionFilter(filters.action);
    }
    setTriggerSearch(Math.random());
    setHoverMenuState(false);
  };

  const handlePageChange = ({ page, pageSize }) => {
    searchLogs(page);
  };

  return (
    <div className={styles.flex}>
      <div className={styles.header}>
        <div>
          <h3>Audit Logs</h3>
        </div>
        <div className={styles.filter_title}>Filter</div>
        <div className={styles.filter_wrapper}>
          <div className={styles.search_element}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={userList}
              value={userFilter}
              size="small"
              sx={{
                "& .MuiInputBase-root": { fontSize: "12px", padding: 0 },
                "& .MuiAutocomplete-root": { fontSize: "12px", padding: 0 },
                fontSize: 12,
                // height: 20,
                padding: 0,
                alignSelf: "center",
                width: 300,
              }}
              renderInput={(params) => <TextField {...params} label="User" />}
              onChange={(e, value) => {
                setUserFilter(value);
              }}
            />
          </div>
          <div className={styles.search_element}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={actionFilter}
              options={actionList}
              size="small"
              sx={{
                "& .MuiInputBase-root": { fontSize: "12px", padding: 0 },
                "& .MuiAutocomplete-root": { fontSize: "12px", padding: 0 },
                fontSize: 12,
                // height: 20,
                padding: 0,
                alignSelf: "center",
                width: 200,
              }}
              renderInput={(params) => <TextField {...params} label="Action" />}
              onChange={(e, value) => {
                setActionFilter(value);
              }}
            />
          </div>
          <div className={styles.search_element}>
            <Date
              value={dateFilter}
              onChange={(value) => setDateFilter(value)}
            />
          </div>

          <div className={styles.search_element}>
            <TextField
              inputRef={subjectRef}
              placeholder="Subject ID"
              value={subjectIdFilter}
              onChange={(e) => setSubjectIdFilter(e.target.value)}
              onBlur={searchLogs}
              onKeyDown={onSubjectKeyDown}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "100px",
                  fontSize: 16,
                },
              }}
            />
          </div>
          <div className={styles.search_element}>
            <TextField
              inputRef={crfRef}
              placeholder="CRF ID"
              value={subjectCRFIdFilter}
              onChange={(e) => setSubjectCRFIdFilter(e.target.value)}
              onBlur={searchLogs}
              onKeyDown={onSubjectKeyDown}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "300px",
                  fontSize: 14,
                },
              }}
            />
          </div>
          <div className={styles.search_element}>
            <TextField
              inputRef={crfFieldRef}
              placeholder="CRF Field ID"
              value={subjectCRFFieldIdFilter}
              onChange={(e) => setSubjectCRFFieldIdFilter(e.target.value)}
              onBlur={searchLogs}
              onKeyDown={onSubjectKeyDown}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "100px",
                  fontSize: 14,
                },
              }}
            />
          </div>
          <div
            className={[styles.search_element, styles.clear_all].join(" ")}
            onClick={clearSearchFilters}
          >
            clear all
          </div>
        </div>
      </div>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={auditLogs.rows || []}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          disableColumnMenu
          pageSize={5}
          rowsPerPageOptions={[5]}
          hideFooterSelectedRowCount
          pageSizeOptions={[]}
          columnHeaderHeight={34}
          rowHeight={34}
          sx={{
            boxShadow: 0,
            border: 1,
            borderColor: "#f0f0f0",
            "& .MuiDataGrid-cell:hover": {
              fontWeight: 700,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#e9f1fd",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
              color: "#333",
            },
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          paginationMode={"server"}
          rowCount={auditLogs.count}
        />
      </div>
    </div>
  );
}
