import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { withRouter } from "util/navigation";
import styles from "./breadcrumbs.module.css";
import { get } from "util/requests";
import { useSelector } from "react-redux";

export default function BreadCrumbs(props) {
  const location = useLocation();

  const path_string = location.pathname;
  let [search_params, setSearchParams] = useSearchParams();
  const active_study_id = useSelector((state) => state.study.active_study_id);
  const [ready, setReady] = useState(false);
  const [crf_name, setCrfName] = useState("");
  useEffect(() => {
    if (path_string.includes("visit")) {
      let path = path_string.split("/").filter((s) => s);
      const crf_id = path[10];
      setReady(false);
      get(`/crf_name_from_id/${crf_id}`).then((ret) => {
        setCrfName(ret.data.name);
        setReady(true);
      });
    } else {
      setReady(true);
    }
  }, [location.pathname]);

  const renderBreadcrumbNode = (node) => {
    return (
      <div className={styles.breadcrumb} key={node.text}>
        {node.link ? (
          <a href={node.link}>{node.text}</a>
        ) : (
          <div>{node.text}</div>
        )}
      </div>
    );
  };

  const renderBreadCrumbs = () => {
    let path = path_string.split("/").filter((s) => s);
    const { g_dom, g_sit } = search_params;

    let nodes = [];
    if (active_study_id === 1) {
      nodes.push({ text: "ACT Global /" });
    } else if (active_study_id === 2) {
      nodes.push({ text: "ECORE /" });
    } else if (active_study_id === 3) {
      nodes.push({ text: "ICH /" });
    }
    if (path.at(0) === "subjects") {
      if (search_params.get("g_dom")) {
        nodes.push({ text: "ACT /" });
      }
      if (search_params.get("g_sit")) {
        nodes.push({ text: "Calgary FMC /" });
      }
      nodes.push({ text: "Subjects" });
    } else if (path_string.includes("view")) {
      const patient_id = path[5];

      nodes = nodes.concat([
        { text: "Subjects /", link: `/subjects` },
        { text: `${patient_id}` },
      ]);
    } else if (path_string.includes("visit")) {
      const patient_id = path[5];
      const visit_index = path[8];
      const crf_id = path[9];

      nodes = nodes.concat([
        { text: "Subjects /", link: "/subjects" },
        {
          text: `${patient_id} /`,
          link: `/study/1/site/1/subjects/${patient_id}/view`,
        },
        { text: crf_name },
      ]);
    }

    nodes = nodes.map((node) => renderBreadcrumbNode(node));
    return nodes;
  };

  if (ready) {
    return (
      <div className={styles.breadcrumb_wrapper}>{renderBreadCrumbs()}</div>
    );
  } else {
    return <div />;
  }
}
