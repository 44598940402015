import React, { useState, useRef, useEffect } from "react";
import styles from "./weight.module.css";
import { TextField } from "@mui/material";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import Radio from "@mui/material/Radio";
import { cloneDeep } from "lodash";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import WeightUI from "./components/weight_ui";

let tempStorage = null;

export default function Weight(props) {
  const {
    key_id,
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    length,
    units,
    placeHolder,
    read_only,
    crfStatus,
    setParentFieldError,
  } = props;

  const [valueObj, setValueObj] = useState((field && field.value_obj) || {});
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  const acceptTempStorage = () => {
    onUpdate(valueObj);
    setShowingCompleteChangeModal(false);
  };

  const closeModal = () => {
    setValueObj(tempStorage);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };

  const currentValueDisplay =
    (valueObj &&
      valueObj.value &&
      valueObj.value2 &&
      valueObj.value3 &&
      `${valueObj.value} ${valueObj.value2} (${valueObj.value3})`) ||
    "---";

  const updateValue = (value_obj) => {
    if (crfStatus === "data_complete") {
      tempStorage = cloneDeep(valueObj);
      setValueObj(value_obj);
      setShowingCompleteChangeModal(true);
    } else {
      onUpdate(value_obj);
    }
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget
                {...props}
                currentValueDisplay={currentValueDisplay}
              />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>

        <div className={styles.right_split}>
          <WeightUI
            {...props}
            field={{ ...field, value_obj: valueObj }}
            onUpdate={updateValue}
            key={props.field.id}
          />
        </div>
      </div>
      <DividerLine />

      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
